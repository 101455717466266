import React from 'react';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';

const InfoBlock = ({ data }) => (
    <div className="mt-4">
    <Row>
        {data.map((item, index) => (
            <Col md="6" key={index}>
                <Card className="bg-light rounded border border-gray-200" style={{ marginBottom: '1.2rem' }}>
                    <CardBody className="p-2">
                        <CardTitle tag="dt" className="font-semibold text-dark mb-1" style={{ fontSize: '1rem' }}>
                            {item.title}
                        </CardTitle>
                        <dd className="text-muted mb-0" style={{ fontSize: '1.1rem', fontWeight: '400', marginBottom: '0' }}>
                            {item.content || '---'}
                        </dd>
                    </CardBody>
                </Card>
            </Col>
        ))}
    </Row>
    </div>
);

export default InfoBlock;
