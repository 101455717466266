import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AfrocentecLogo from '../assets/images/afrocentec_logo.png';

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col sm={6}>
              {new Date().getFullYear()} © <a href="https://www.zamace.co.zm">Zambian Commodities Exchange Ltd.</a> All Rights Reserved.
            </Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
              <a href="https://afrocentec.com">Developed by <img src={AfrocentecLogo} alt="Afrocentec" style={{ width: "100px" }} /></a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;


