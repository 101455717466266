import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as app_consts from "../../utils/constants";
import classnames from 'classnames';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { RETRIEVE_ENTITY_ERR_MSG, RETRIEVE_ENTITY_SUCCESS_MSG } from "../../utils/response_messages_constants";
import { RETRIEVE_ENTITY_DETAILS_API } from "../../api/api_endpoints";
import { auth_actions_helper } from "../../actions/actions_helper";
import Loader from "../Common/Loader";
import InfoBlock from "../Common/InfoBlock";
import { fail_retrieve_entity_details, loading_retrieve_entity_details, reset_retrieve_entity_details, success_retrieve_entity_details } from "../../reducers/private_reducers/entities/retrieve_entity_details_slice";
import DocumentViewer from "../../Layouts/DocumentViewer";

const ViewEntityDetailsModal = ({ isOpen, toggle, organization_id }) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null)
    const [entityDetails, setEntityDetailsData] = useState("")

    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState('');

    const handleViewImage = (imageUrl) => {
        setImageUrl(imageUrl);
        setIsImageModalOpen(true);
    };

    const toggleImageModal = () => {
        setImageUrl('')
        setIsImageModalOpen(!isImageModalOpen);
    }

    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        if (isOpen) {
            setActiveTab('1')
            dispatch(auth_actions_helper(loading_retrieve_entity_details, success_retrieve_entity_details, fail_retrieve_entity_details, RETRIEVE_ENTITY_ERR_MSG, RETRIEVE_ENTITY_SUCCESS_MSG, app_consts.GET, `${RETRIEVE_ENTITY_DETAILS_API}/${organization_id}`));
        }

    }, [dispatch, isOpen, organization_id]);

    const retrieve_entity_details_listener = useSelector((state) => state.retrieve_entity_details?.retrieve_entity_details);

    useEffect(() => {
        if (retrieve_entity_details_listener?.status !== app_consts.IDLE) {
            if (retrieve_entity_details_listener?.status === app_consts.LOADING) {
                setLoading(true);
            }
            else {
                if (retrieve_entity_details_listener?.status === app_consts.FAIL) {
                    setError(retrieve_entity_details_listener?.message);
                }
                else if (retrieve_entity_details_listener?.status === app_consts.SUCCESS) {
                    setEntityDetailsData(retrieve_entity_details_listener?.data);
                }
                dispatch(reset_retrieve_entity_details());
                setLoading(false);
            }
        }
    }, [retrieve_entity_details_listener]);

    const entitySummeryInfo = [
        { title: 'Account ID', content: entityDetails?.entity?.organization_id },
        { title: 'Entity Registration Number', content: entityDetails?.entity?.entity_reg_no },
        { title: 'Entity Tax Number', content: entityDetails?.entity?.entity_tax_no },
        { title: 'Entity Name', content: entityDetails?.entity?.entity_name },
        { title: 'Entity Type', content: entityDetails?.entity?.entity_type?.replace(/_/g, ' ').toUpperCase() },
        { title: 'Entity Category', content: entityDetails?.entity?.entity_category?.replace(/_/g, ' ').toUpperCase() },
        { title: 'Entity Email', content: entityDetails?.entity?.entity_email },
        { title: 'Entity Phone', content: entityDetails?.entity?.entity_phone },
        { title: 'Status', content: entityDetails?.entity?.status?.replace(/_/g, ' ').toUpperCase() },
        { title: 'Active', content: entityDetails?.entity?.active ? 'YES' : 'NO' },
        { title: 'Verified', content: entityDetails?.entity?.verified ? 'YES' : 'NO' },
        { title: 'Country', content: entityDetails?.entity?.country },
        { title: 'Province', content: entityDetails?.entity?.province },
        { title: 'District', content: entityDetails?.entity?.district },
        { title: 'Physical Address', content: entityDetails?.entity?.physical_address },
        { title: 'Postal Address', content: entityDetails?.entity?.postal_address },
    ];

    const userKycInfo = [
        { title: 'First Name', content: entityDetails?.user_profiles?.[0]?.user?.first_name },
        { title: 'Middle Name', content: entityDetails?.user_profiles?.[0]?.user?.middle_name },
        { title: 'Last Name', content: entityDetails?.user_profiles?.[0]?.user?.last_name },
        { title: 'Gender', content: entityDetails?.user_profiles?.[0]?.user?.gender?.toUpperCase() },
        { title: 'Birth Date', content: new Date(entityDetails?.user_profiles?.[0]?.user?.birth_date).toLocaleDateString() },
        { title: 'Nationality', content: entityDetails?.user_profiles?.[0]?.user?.nationality },
        { title: 'Tax ID', content: entityDetails?.user_profiles?.[0]?.user?.tax_id },
        { title: 'Province', content: entityDetails?.user_profiles?.[0]?.user?.province },
        { title: 'District', content: entityDetails?.user_profiles?.[0]?.user?.district },
        { title: 'Physical Address', content: entityDetails?.user_profiles?.[0]?.user?.physical_address },
        {
            title: 'Profile Image', content: (
                <Button color="primary" onClick={() => handleViewImage(entityDetails?.user_profiles?.[0]?.user?.profile_image_url)}>
                    View Profile Image
                </Button>
            )
        },
        {
            title: 'Front NRC Image', content: (
                <Button color="primary" onClick={() => handleViewImage(entityDetails?.user_profiles?.[0]?.user?.id_front_image)}>
                    View Front NRC Image
                </Button>
            )
        },
        {
            title: 'Back NRC Image', content: (
                <Button color="primary" onClick={() => handleViewImage(entityDetails?.user_profiles?.[0]?.user?.id_back_image)}>
                    View Back NRC Image
                </Button>
            )
        },
    ];

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} size="xl" scrollable>
                <ModalHeader toggle={toggle}>Entity Details</ModalHeader>
                {loading ?
                    <Loader
                        loader_type={app_consts.LOGO_LOADER}
                        loading_message={"Loading Entity Details..."} />
                    : error ?
                        <Loader error={error} />
                        : <ModalBody>
                            <div>
                                <Nav tabs className="nav nav-tabs nav-justified nav-border-top-success mb-3">
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { toggleTab('1'); }}
                                        >
                                            Account Info Summary
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { toggleTab('2'); }}
                                        >
                                            User KYC Information
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({ active: activeTab === '3' })}
                                            onClick={() => { toggleTab('3'); }}
                                        >
                                            Data Information 2
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <InfoBlock data={entitySummeryInfo} />
                                </TabPane>
                                <TabPane tabId="2">
                                    <InfoBlock data={userKycInfo} />
                                </TabPane>
                                <TabPane tabId="3">
                                    {/* <InfoBlock data={info} /> */}
                                </TabPane>
                            </TabContent>
                        </ModalBody>}
                <ModalFooter>
                    <Button color="light" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={isImageModalOpen} toggle={toggleImageModal} centered size="lg">
                <ModalHeader toggle={toggleImageModal}>Image</ModalHeader>
                <DocumentViewer
                    url={imageUrl}
                />
                <ModalFooter>
                <Button color="light" onClick={toggleImageModal}>Close</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default ViewEntityDetailsModal;
