import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { ProfileProvider } from './Components/Context/ProfileContext';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ProfileProvider>
        <App />
      </ProfileProvider>
    </BrowserRouter>
  </>
);