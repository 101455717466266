import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../utils/constants";

const initialState = {
    auth_sign_in: { message: "idle" },
};

export const auth_sign_in_Slice = createSlice({
    name: "auth_sign_in",
    initialState,
    reducers: {
        success_auth_sign_in: (state, action) => {
            const tokenCreatedAt = new Date().toISOString();
            if (action?.payload?.token)
            {
                localStorage.setItem('2faAuthToken', action?.payload?.token);
                localStorage.setItem('tokenCreatedAt', tokenCreatedAt);
                state.auth_sign_in = { status: app_consts.SUCCESS, data: action?.payload };
            }
            else
            {
                state.auth_sign_in = { status: app_consts.FAIL, message: "Sign In Failed. Please Contact System Administrator." };
            }
        },
        fail_auth_sign_in: (state, action) => {
            state.auth_sign_in = { status: app_consts.FAIL, message: action.payload };
        },
        reset_auth_sign_in: (state) => {
            state.auth_sign_in = { status: app_consts.IDLE };
        },
        loading_auth_sign_in: (state) => {
            state.auth_sign_in = { status: app_consts.LOADING };
        },
    }
});

export const { success_auth_sign_in, fail_auth_sign_in, reset_auth_sign_in, loading_auth_sign_in } = auth_sign_in_Slice.actions;

export default auth_sign_in_Slice.reducer;