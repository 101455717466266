import React, { createContext, useContext, useState } from 'react';

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [selectedProfileType, setSelectedProfileType] = useState(null);

  const setProfileType = (profileType) => {
    setSelectedProfileType(profileType);
  };

  return (
    <ProfileContext.Provider value={{ selectedProfileType, setProfileType }}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};
