import React from "react";
import CoverSignIn from "../pages/AuthenticationInner/Login/CoverSignIn";
import CoverPasswReset from "../pages/AuthenticationInner/PasswordReset/CoverPasswReset";
import CoverTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify";
import Cover404 from "../pages/AuthenticationInner/Errors/Cover404";
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";
import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
import RedirectHelper from "../pages/RedirectHelper.jsx";
import ConfirmTwoFa from "../pages/AuthenticationInner/TwoStepVerification/ConfirmTwoFa.js";
import UserManagement from "../pages/Exchange/UserManagement.jsx";
import TicketManagement from "../pages/Exchange/TicketManagement.jsx";
import CalendarManagement from "../pages/Exchange/CalendarManagement.jsx";


const authProtectedRoutes = [
  { path: "/", redirect: true, redirect_path: "/dashboard" },
  { path: "*", redirect: true, redirect_path: "/loading" },
  { path: "/loading", component: <RedirectHelper /> },
  { path: "/dashboard", component: <UserManagement /> },
  { path: "/exchange/membership/list", component: <UserManagement /> },
  { path: "/exchange/calendar/list", component: <CalendarManagement /> },
  { path: "/exchange/tickets/list", component: <TicketManagement /> },
];

const publicRoutes = [
  { path: "/", redirect: true, redirect_path: "/login" },
  { path: "*", redirect: true, redirect_path: "/login" },
  { path: "/login", component: <CoverSignIn /> },
  { path: "/2fa/:email", component: <CoverTwosVerify /> },
  { path: "/verify-2fa/:email", component: <ConfirmTwoFa /> },
  { path: "/reset-password", component: <CoverPasswReset /> },
  { path: "/change-password/:email/:otp", component: <CoverPasswCreate /> },
  { path: "/auth-404-cover", component: <Cover404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },
];

export { authProtectedRoutes, publicRoutes };
