module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    // API_URL: "https://api-node.themesbrand.website",
    API_URL: "http://localhost:4000/api/v1/",
    // API_URL: "https://devapi.zamace.co.zm/api/v1/",
    // API_URL: "http://192.168.8.111:4000/api/v1/",
  }
};