import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WebAppsDropdown from "../Components/Common/WebAppsDropdown";
import { useProfile } from "../Components/Context/ProfileContext";

const Navdata = () => {

  const { selectedProfileType, setProfileType } = useProfile();
  const { member_type_id } = useParams();  

  useEffect(() => {
    if (!selectedProfileType && member_type_id) {
      setProfileType({ ...selectedProfileType, member_type_id });
    }
  }, [member_type_id, selectedProfileType, setProfileType]);
  

  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isLanding, setIsLanding] = useState(false);
  const [isEvents, setIsEvents] = useState(false);
  const [isTickets, setIsTickets] = useState(false);
  const [isMemberShip, setIsMemberShip] = useState(false);


  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  const exchangeMenuItems = [
    {
      label: "TradeEase Dashboard",
      isHeader: true,
    },
    {
      id: "home",
      label: "Home",
      icon: " ri-home-5-line",
      link: `/dashboard`,
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },

    },
    {
      id: "membership",
      label: "Membership",
      icon: "ri-team-fill",
      link: "/#",
      stateVariables: isMemberShip,
      click: function (e) {
        e.preventDefault();
        setIsMemberShip(!isMemberShip);
        setIscurrentState("MemberShip");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "membership",
          label: "User Management",
          link: `/exchange/membership/list`,
          parentId: "dashboard",
        },
        // {
        //   id: "dash_users",
        //   label: "Dashboard Users",
        //   link: `/exchange/membership/list`,
        //   parentId: "dashboard",
        // },
      ],
    },
    {
      id: "calendar",
      label: "Calendar",
      icon: "ri-calendar-event-fill",
      link: "/#",
      stateVariables: isEvents,
      click: function (e) {
        e.preventDefault();
        setIsEvents(!isEvents);
        setIscurrentState("Events");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "calendar",
          label: "Calendar Management",
          link: `/exchange/calendar/list`,
          parentId: "dashboard",
        },
      ],
    },
    {
      id: "ticket",
      label: "Support Tickets",
      icon: "ri-file-text-fill",
      link: "/#",
      stateVariables: isTickets,
      click: function (e) {
        e.preventDefault();
        setIsTickets(!isTickets);
        setIscurrentState("Tickets");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "ticket",
          label: "Ticket Management",
          link: `/exchange/tickets/list`,
          parentId: "dashboard",
        },
      ],
    }
  ];

  const url = window.location.href;
  const segments = url.split('/');
  let selectedProfileTypeFromURL;

  // Find the first occurrence of a known profile type in the URL segments
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    if (segment === "exchange") {
      selectedProfileTypeFromURL = segment;
      break;
    }
  }
  // Define default menu items
  let menuItems = exchangeMenuItems;

  // Set menu items based on selectedProfileType selected by the user
  if (selectedProfileType?.type) {
    if (selectedProfileType.type === "exchange") {
      menuItems = exchangeMenuItems;
    // } else if (selectedProfileType.type === "broker") {
    //   menuItems = brokerMenuItems;
    // } else if (selectedProfileType.type === "warehouse_operator") {
    //   menuItems = warehouseOperatorMenuItems;
    // } else if (selectedProfileType.type === "cooperative") {
    //   menuItems = cooperativeMenuItems;
    // } else if (selectedProfileType.type === "invester") {
    //   menuItems = investorMenuItems;
    // } else if (selectedProfileType.type === "service_provider") {
    //   menuItems = serviceProviderMenuItems;
    }
  } else if (selectedProfileTypeFromURL) {
    if (selectedProfileTypeFromURL === "exchange") {
      menuItems = exchangeMenuItems;
    // } else if (selectedProfileTypeFromURL === "broker") {
    //   menuItems = brokerMenuItems;
    // } else if (selectedProfileTypeFromURL === "warehouse_operator" || selectedProfileTypeFromURL === "warehouse") {
    //   menuItems = warehouseOperatorMenuItems;
    // } else if (selectedProfileTypeFromURL === "cooperative") {
    //   menuItems = cooperativeMenuItems;
    // } else if (selectedProfileTypeFromURL === "invester") {
    //   menuItems = investorMenuItems;
    // } else if (selectedProfileTypeFromURL === "service_provider") {
    //   menuItems = serviceProviderMenuItems;
    }
  }


  return <React.Fragment>{menuItems}</React.Fragment>;

};
export default Navdata;
