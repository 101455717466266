import React, { useEffect, useState, useCallback, useMemo } from "react";
import classnames from "classnames";
import * as app_consts from "../../utils/constants";
import { Col, Container, Row, Card, CardHeader, CardBody, Button } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";
import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RETRIEVE_EVENT_LIST_ERR_MSG, RETRIEVE_EVENT_LIST_SUCCESS_MSG } from "../../utils/response_messages_constants";
import { RETRIEVE_EVENT_LIST_API } from "../../api/api_endpoints";
import { auth_actions_helper } from "../../actions/actions_helper";
import { fail_event_list, loading_event_list, reset_event_list, success_event_list } from "../../reducers/private_reducers/events/event_list_slice";
import CreateTrainingEventModal from "../../Components/Modals/CreateTrainingEventModal";

const CalendarManagement = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [training_events, setEventList] = useState([]);

    useEffect(() => {
        dispatch(auth_actions_helper(loading_event_list, success_event_list, fail_event_list, RETRIEVE_EVENT_LIST_ERR_MSG, RETRIEVE_EVENT_LIST_SUCCESS_MSG, app_consts.GET, RETRIEVE_EVENT_LIST_API));
    }, []);

    const successFunction = () => {
        dispatch(auth_actions_helper(loading_event_list, success_event_list, fail_event_list, RETRIEVE_EVENT_LIST_ERR_MSG, RETRIEVE_EVENT_LIST_SUCCESS_MSG, app_consts.GET, RETRIEVE_EVENT_LIST_API));
    };

    const event_list_listener = useSelector((state) => state.event_list?.event_list);

    useEffect(() => {
        if (event_list_listener?.status !== app_consts.IDLE) {
            if (event_list_listener?.status === app_consts.LOADING) {
                setLoading(true);
            } else {
                if (event_list_listener?.status === app_consts.FAIL) {
                    setError(event_list_listener?.message);
                } else if (event_list_listener?.status === app_consts.SUCCESS) {
                    setEventList(event_list_listener?.data);
                }
                reset_event_list();
                setLoading(false);
            }
        }
    }, [event_list_listener, reset_event_list]);

    const [modalOpen, setModalOpen] = useState(false);
    const [outboundData, setOutboundData] = useState(null);

    const toggleModal = (outboundData) => {
        setOutboundData(outboundData);
        setModalOpen(!modalOpen);
    };

    const [createModalOpen, setCreateModalOpen] = useState(false);

    const toggleCreateModal = () => {
        setCreateModalOpen(!createModalOpen);
    };

    const columns = useMemo(
        () => [
            {
                Header: "REFERENCE NO",
                accessor: "event_reference_no",
                filterable: false,
                Cell: (contact) => (
                    <>
                        <div className="d-flex align-items-center">
                            {contact.row.original?.event_reference_no}
                        </div>
                    </>
                ),
            },
            {
                Header: "EVENT NAME",
                accessor: "name",
                filterable: false,
                Cell: (contact) => (
                    <>
                        <div className="d-flex align-items-center">
                            {contact.row.original?.name}
                        </div>
                    </>
                ),
            },
            {
                Header: "LOCATION",
                accessor: "location",
                filterable: false,
                Cell: (contact) => (
                    <>
                        <div className="d-flex align-items-center">
                                {contact.row.original?.location}
                        </div>
                    </>
                ),
            },
            {
                Header: "TYPE",
                accessor: "type",
                filterable: false,
                Cell: (outbound) => (
                    <>
                        <div className="d-flex align-items-center">
                                {outbound.row.original?.type?.replace(/_/g, ' ').toUpperCase()}
                        </div>
                    </>
                ),
            },
            {
                Header: "SLOTS",
                accessor: "slots",
                filterable: false,
                Cell: (outbound) => (
                    <>
                        <div className="d-flex align-items-center">
                                {outbound.row.original?.slots}
                        </div>
                    </>
                ),
            },
            {
                Header: "ATTENDEEDS",
                accessor: "attendees",
                filterable: false,
                Cell: (outbound) => (
                    <>
                        <div className="d-flex align-items-center">
                        <div className="flex-grow-1 ms-5 name">
                        {outbound.row.original?.attendees}
                        </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "STATUS",
                accessor: "status",
                filterable: false,
                Cell: (contact) => (
                    <>
                        <div className="d-flex align-items-center">
                            {contact.row.original?.status?.replace(/_/g, ' ').toUpperCase()}
                        </div>
                    </>
                ),
            },
            {
                Header: "ACTION",
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0">
                            <li className="list-inline-item">
                                <Button color="light"
                                // onClick={() => toggleModal(cellProps?.row?.original)}
                                >{" "}View</Button>
                            </li>
                        </ul>
                    );
                },
            },
        ],
        []
    );

    document.title = "Calendar Management | TradeEase Integrated Digital Platform";

    return (
        <React.Fragment>
            <div className="page-content">

                <CreateTrainingEventModal isOpen={createModalOpen} toggle={() => toggleCreateModal()} id={null} successFunction={successFunction} />
                <Container fluid>
                    <BreadCrumb title="Calendar Management" pageTitle="Home" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader className="border-0">
                                    <div className="d-flex align-items-center flex-wrap gap-2">
                                        <h5 className="card-title mb-0 flex-grow-1">Calendar ({training_events?.length})</h5>
                                        <div className="flex-grow-1">
                                        </div>
                                        <div className="flex-shrink-0">
                                            <div className="hstack text-nowrap gap-2">
                                                <button
                                                    className="btn btn-success add-btn"
                                                    onClick={() => {
                                                        toggleCreateModal();
                                                    }}
                                                >
                                                    <i className="ri-add-fill me-1 align-bottom"></i>
                                                    Create Event
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className="pt-0">
                                    <div>
                                        {loading ? (
                                            <Loader
                                                loader_type={app_consts.LOGO_LOADER}
                                                loading_message={"Loading Events..."} />
                                        ) : error ? (
                                            <Loader error={error} />
                                        ) : success_event_list && training_events?.length ? (
                                            <TableContainer
                                                columns={columns}
                                                data={training_events || []}
                                                isGlobalFilter={true}
                                                isAddUserList={false}
                                                customPageSize={10}
                                                className="custom-header-css"
                                                divClass="table-responsive table-card mb-3"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light"
                                                isContactsFilter={true}
                                                SearchPlaceholder="Search..."
                                            />
                                        ) : (
                                            <div className="d-flex justify-content-center align-items-center">
                                                No Events Available
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CalendarManagement;
