import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../utils/constants";

const initialState = {
    auth_verify_2fa_otp: { status: "idle" },
};

export const auth_verify_2fa_otp_Slice = createSlice({
    name: "auth_verify_2fa_otp",
    initialState,
    reducers: {
        success_auth_verify_2fa_otp: (state, action) => {
            state.auth_verify_2fa_otp = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_auth_verify_2fa_otp: (state, action) => {
            state.auth_verify_2fa_otp = { status: app_consts.FAIL, message: action.payload };
        },
        reset_auth_verify_2fa_otp: (state) => {
            state.auth_verify_2fa_otp = { status: app_consts.IDLE };
        },
        loading_auth_verify_2fa_otp: (state) => {
            state.auth_verify_2fa_otp = { status: app_consts.LOADING };
        },
    }
});

export const { success_auth_verify_2fa_otp, fail_auth_verify_2fa_otp, reset_auth_verify_2fa_otp, loading_auth_verify_2fa_otp } = auth_verify_2fa_otp_Slice.actions;

export default auth_verify_2fa_otp_Slice.reducer;