import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../utils/constants";

const initialState = {
    auth_change_password: { status: "idle" },
};

export const auth_change_password_Slice = createSlice({
    name: "auth_change_password",
    initialState,
    reducers: {
        success_auth_change_password: (state, action) => {
            state.auth_change_password = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_auth_change_password: (state, action) => {
            state.auth_change_password = { status: app_consts.FAIL, message: action.payload };
        },
        reset_auth_change_password: (state) => {
            state.auth_change_password = { status: app_consts.IDLE };
        },
        loading_auth_change_password: (state) => {
            state.auth_change_password = { status: app_consts.LOADING };
        },
    }
});

export const { success_auth_change_password, fail_auth_change_password, reset_auth_change_password, loading_auth_change_password } = auth_change_password_Slice.actions;

export default auth_change_password_Slice.reducer;