import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as app_consts from "../../utils/constants";
import Icon from '../../assets/images/svg/Icon';

const Loader = ({ error, loader_type = app_consts.SPINNER_LOADER, loading_message }) => {

    const [opacity, setOpacity] = useState(1);
    const [direction, setDirection] = useState('decreasing');
    const [loading_error, setLoadingError] = useState(error);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setOpacity(prevOpacity => {
                if (prevOpacity <= 0.2)
                {
                    setDirection('increasing');
                    return prevOpacity + 0.1;
                } else if (prevOpacity >= 1)
                {
                    setDirection('decreasing');
                    return prevOpacity - 0.1;
                } else
                {
                    return direction === 'decreasing' ? prevOpacity - 0.1 : prevOpacity + 0.1;
                }
            });
        }, 100); // Adjust the speed of the animation by changing the interval (in ms)

        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, [direction]);

    React.useEffect(() => {
        if (error)
        {
            toast.error(error, {
                position: "top-right",
                hideProgressBar: false,
                progress: undefined,
                toastId: ""
            });
        }
        setLoadingError(error);
    }, [error]);

    return (
        <>
            {
                loading_error && loading_error?.length ?
                    <>
                        <div className='d-flex justify-center Width 100% justify-content-center mx-2 mt-2 p-4' >
                            <div className='d-flex flex-column justify-content-center' >
                                <span className='d-flex  items-center text-body mt-8 fs-4 text-center p-4'>
                                    <i className="ri-error-warning-line text-danger fs-3" style={{ marginRight: 10 }}></i>
                                    {loading_error}
                                </span>
                                <div className="d-flex justify-content-center mx-2 mt-2">
                                    <Icon height='15mm' width='25mm' />
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        {
                            loader_type === app_consts.LOGO_LOADER ?
                                <>
                                    <div className='d-flex justify-center Width 100% justify-content-center mx-2 mt-2 p-4' style={{ opacity: opacity }} >
                                        <div className='d-flex flex-column justify-content-center' >
                                            <div className="d-flex justify-content-center mx-2 mt-2">
                                                <Icon height='20mm' width='30mm' />
                                            </div>
                                            <span className='text-body mt-8 fs-4 text-center p-4'>{loading_message}</span>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    {!error && (
                                        <div className="d-flex justify-content-center mx-2 mt-2" style={{ height: "100vh" }}>
                                            <Spinner color="primary"> Loading... </Spinner>
                                        </div>
                                    )}
                                </>
                        }
                    </>
            }
        </>
    );
};

export default Loader;


// import React from 'react';
// import { Alert, Spinner } from 'reactstrap';

// const Loader = ({ error }) => {
//     return (
//         <div className="d-flex justify-content-center mx-2 mt-2">
//             {error ? ( <Alert color="danger">{error}</Alert>
//             ) : (
//                 <Spinner color="primary"> Loading... </Spinner>
//             )}
//         </div>
//     );
// };

// export default Loader;
