import { createSlice } from "@reduxjs/toolkit";
import * as layout_consts from "../../../utils/layout_constants";
import { change_layout_position_type } from "../../private_reducers/layout/layout_Slice";

const initialState = {
 layout_slice: {
  layoutType: layout_consts.layoutTypes.HORIZONTAL,
  leftSidebarType: layout_consts.leftSidebarTypes.LIGHT,
  layoutModeType: layout_consts.layoutModeTypes.LIGHTMODE,
  layoutWidthType: layout_consts.layoutWidthTypes.FLUID,
  layoutPositionType: layout_consts.layoutPositionTypes.FIXED,
  topbarThemeType: layout_consts.topbarThemeTypes.LIGHT,
  leftsidbarSizeType: layout_consts.leftsidbarSizeTypes.LEFTSIZEBARSIZE_DEFAULT,
  leftSidebarViewType: layout_consts.leftSidebarViewTypes.LEFTSIZEBARVIEW_DEFAULT,
  leftSidebarImageType: layout_consts.leftSidebarImageTypes.NONE,
  preloader: layout_consts.preloaderTypes.ENABLE,
 },
};

export const layout_Slice = createSlice({
 name: "layout_slice",
 initialState,
 reducers: {
  change_layout: (state, action) => {
   state.layout_slice = { ...state, layoutType: action.payload };
  },
  change_layout_mode: (state, action) => {
   state.layout_slice = { ...state, layoutModeType: action.payload };
  },
  change_sidebar_theme: (state, action) => {
   state.layout_slice = { ...state, leftSidebarType: action.payload };
  },
  change_layout_width: (state, action) => {
   state.layout_slice = { ...state, layoutWidthType: action.payload };
  },
  change_topbar: (state, action) => {
   state.layout_slice = { ...state, topbarThemeType: action.payload };
  },
  change_sidebar_size: (state, action) => {
   state.layout_slice = { ...state, leftsidbarSizeType: action.payload };
  },
  change_sidebar_view: (state, action) => {
   state.layout_slice = { ...state, leftSidebarViewType: action.payload };
  },
  change_sidebar_image_type: (state, action) => {
   state.layout_slice = { ...state, leftSidebarImageType: action.payload };
  },
  reset_value: (state, action) => {
   state.layout_slice = { ...state, resetValue: action.payload };
  },
 }

});

export const { change_layout, change_layout_mode, change_sidebar_theme, change_layout_width, change_topbar, change_sidebar_size, change_sidebar_view, change_sidebar_image_type, reset_value, change_preloader } = layout_Slice.actions;

export default layout_Slice.reducer;
