import React from 'react';
import './assets/scss/themes.scss';
import { Navigate, Route, Routes } from 'react-router-dom';
import { authProtectedRoutes, publicRoutes } from './Routes/allRoutes';
import NonAuthLayout from './Layouts/NonAuthLayout';
import VerticalLayout from "./Layouts/index";
import { AuthProtected } from './Routes/AuthProtected';
import { Provider } from 'react-redux';
import { public_routes_store } from './app_store/public_routes_store';
import { ToastContainer } from 'react-toastify';
import { private_routes_store } from './app_store/private_routes_store';

// Let Login Status
let login_status;

//Set Login State before React App fully Initializes
if (localStorage.getItem("authToken"))
{
  // Logs in User if Variables are present
  login_status = true;
} else
{
  // Logs out user if Variables aren't present
  login_status = false;
}

function App () {
  return (
    <>
      {/* Expose Routes According to Login Status */}
      {
        // Logged In
        login_status ?
          <>
            {/* Surrond by Respective Reducer */}
            <Provider store={private_routes_store} >
              <Routes>
                <Route>
                  {authProtectedRoutes.map((route, idx) => (
                    route.redirect ? (
                      <Route
                        key={idx}
                        path={route.path}
                        element={<Navigate to={route.redirect_path} />}
                      />
                    ) : (
                      <Route
                        key={idx}
                        path={route.path}
                        element={
                          <AuthProtected>
                            <VerticalLayout>{route.component}</VerticalLayout>
                          </AuthProtected>
                        }
                      />
                    )
                  ))}
                </Route>
              </Routes>
              <ToastContainer />
            </Provider>
          </>
          :
          // Not Logged In
          <>
            {/* Surrond by Respective Reducer */}
            <Provider store={public_routes_store} >
              <Routes>
                <Route>
                  {publicRoutes.map((route, idx) => (
                    route.redirect ? (
                      <Route
                        key={idx}
                        path={route.path}
                        element={<Navigate to={route.redirect_path} />}
                      />
                    ) : (
                      <Route
                        key={idx}
                        path={route.path}
                        element={
                          <NonAuthLayout>
                            {route.component}
                          </NonAuthLayout>
                        }
                      />
                    )
                  ))}
                </Route>
              </Routes>
              <ToastContainer />
            </Provider>
          </>
      }
    </>
  );
}

export default App;