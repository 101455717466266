import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, Col, Container, Row, Form, FormFeedback, Label, Input, Spinner, Alert } from 'reactstrap';
import AuthSlider from '../authCarousel';
import * as Yup from "yup";
import { useFormik } from "formik";
import * as app_consts from '../../../utils/constants';
import { no_auth_actions_helper } from '../../../actions/actions_helper';
import { fail_auth_resend_otp, loading_auth_resend_otp, reset_auth_resend_otp, success_auth_resend_otp } from '../../../reducers/public_reducers/auth/auth_resend_otp';
import { RESEND_CODE_ERR_MSG, RESEND_CODE_SUCCESS_MSG } from '../../../utils/response_messages_constants';
import { SEND_RESET_OTP_API } from '../../../api/api_endpoints';

const CoverPasswReset = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [loadingMessage, setloadingMessage] = useState("");

    document.title = "Reset Password | TradeEase Integrated Digital Platform";

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
        }),
        onSubmit: (values) => {
            setError("")
            dispatch(no_auth_actions_helper(loading_auth_resend_otp, success_auth_resend_otp, fail_auth_resend_otp, RESEND_CODE_ERR_MSG, RESEND_CODE_SUCCESS_MSG, app_consts.POST, SEND_RESET_OTP_API, { email: values.email }));
        }
    });

    const resend_otp_listener = useSelector((state) => state.auth_resend_otp.auth_resend_otp);
    
    useEffect(() => {
        if (resend_otp_listener.status !== app_consts.IDLE) {
            if (resend_otp_listener.status === app_consts.LOADING) {
                setLoading(true);
                setloadingMessage("Sending Code...");
            }
            else {
                if (resend_otp_listener.status === app_consts.FAIL) {
                    setError(resend_otp_listener.message);
                    dispatch(reset_auth_resend_otp());
                    setLoading(false);
                }
                else if (resend_otp_listener.status === app_consts.SUCCESS) {
                    setLoading(false);
                    navigate(`/verify-2fa/${validation.values.email}`);
                }
            }
        }
    }, [resend_otp_listener, navigate]);

    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden">
                                    <Row className="justify-content-center g-0">
                                        <AuthSlider />

                                        <Col lg={6}>
                                            <div className="p-lg-5 p-4">
                                                <h5 className="text-primary">Forgot Password?</h5>
                                                <p className="text-muted">Reset your password</p>

                                                <div className="mt-2 text-center">
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/rhvddzym.json"
                                                        trigger="loop"
                                                        colors="primary:#0ab39c"
                                                        className="avatar-xl"
                                                        style={{ width: "120px", height: "120px" }}>
                                                    </lord-icon>
                                                </div>

                                                <div className="alert alert-borderless alert-warning text-center mb-2 mx-2" role="alert">
                                                    Enter your email and a verification code will be sent to you!
                                                </div>
                                                {error && (
                                                <>
                                                    <Alert color="danger">
                                                        <div className='d-flex flex-row align-items-center'>
                                                            <i className='ri-error-warning-line fs-16' />
                                                            <div className='mx-2'>{error}</div>
                                                        </div>
                                                    </Alert>
                                                </>
                                                )}
                                                <div className="p-2">
                                                    <Form onSubmit={validation.handleSubmit}>
                                                        <div className="mb-4">
                                                            <Label className="form-label">Email</Label>
                                                            <Input
                                                                type="email"
                                                                className="form-control"
                                                                id="email"
                                                                placeholder="Enter email address"
                                                                name="email"
                                                                value={validation.values.email}
                                                                onBlur={validation.handleBlur}
                                                                onChange={validation.handleChange}
                                                                invalid={validation.errors.email && validation.touched.email ? true : false}
                                                            />
                                                            {validation.errors.email && validation.touched.email ? (
                                                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mt-4">
                                                    <Button color="success" disabled={error ? null : loading ? true : false} className="btn btn-success w-100 flex flex-row" type="submit">
                                                        {
                                                            loading ? <><Spinner size="sm" className='me-2' />  {loadingMessage} </> : "Send Code"
                                                        }
                                                    </Button>
                                                </div>
                                                    </Form>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p className="mb-0">I remember my password... <Link to="/login" className="fw-bold text-primary text-decoration-underline"> Click here </Link> </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                
            </div>
        </React.Fragment>
    );
};

export default CoverPasswReset;