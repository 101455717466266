import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../utils/constants";

const initialState = {
    auth_verify_2fa: { message: "idle" },
};

export const auth_verify_2fa_Slice = createSlice({
    name: "auth_verify_2fa",
    initialState,
    reducers: {
        success_auth_verify_2fa: (state, action) => {
            const tokenCreatedAt = new Date().toISOString();
            if (action?.payload?.token)
            {
                localStorage.removeItem('2faAuthToken');
                localStorage.setItem('authToken', action?.payload?.token);
                localStorage.setItem('tokenCreatedAt', tokenCreatedAt);
                state.auth_verify_2fa = { status: app_consts.SUCCESS, data: action?.payload };
            }
            else
            {
                state.auth_verify_2fa = { status: app_consts.FAIL, message: "Sign In Failed. Please Contact System Administrator." };
            }
        },
        fail_auth_verify_2fa: (state, action) => {
            state.auth_verify_2fa = { status: app_consts.FAIL, message: action.payload };
        },
        reset_auth_verify_2fa: (state) => {
            state.auth_verify_2fa = { status: app_consts.IDLE };
        },
        loading_auth_verify_2fa: (state) => {
            state.auth_verify_2fa = { status: app_consts.LOADING };
        },
    }
});

export const { success_auth_verify_2fa, fail_auth_verify_2fa, reset_auth_verify_2fa, loading_auth_verify_2fa } = auth_verify_2fa_Slice.actions;

export default auth_verify_2fa_Slice.reducer;