import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as app_consts from "../../utils/constants";
import {
    Col,
    Container,
    Row,
    Label,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    ModalFooter,
    Table,
    FormFeedback,
    ButtonGroup,
    Button,
    FormGroup,
    Alert,
    Spinner
} from "reactstrap";
import * as yup from 'yup';
import Select from 'react-select';
import { useFormik } from "formik";
import { CREATE_TRAINING_EVENT_ERR_MSG, CREATE_TRAINING_EVENT_SUCCESS_MSG } from "../../utils/response_messages_constants";
import { CREATE_TRAINING_EVENT_API } from "../../api/api_endpoints";
import { auth_actions_helper } from "../../actions/actions_helper";
import { fail_create_training_event, loading_create_training_event, reset_create_training_event, success_create_training_event } from "../../reducers/private_reducers/events/create_training_event_slice.js";

const CreateTrainingEventModal = ({ isOpen, toggle, id, successFunction }) => {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [createEventLoading, setCreateEventLoading] = useState(false);
    const [selectedCategories, setSelectedCategories] = React.useState([]);

    useEffect(() => {
        if (!isOpen) {
            formik.resetForm();
        }
    }, [isOpen]);

    const eventSchema = yup.object().shape({
        name: yup.string().required('Eevnt Name is required'),
        type: yup.string().required('Type is required'),
        location: yup.string().required('This Field is required'),
        // category: yup.array().of(yup.string()).min(1, 'At least one category is required').required('Category is required'),
        slots: yup.string().required('Slots are required'),
        price: yup.string().required('Price is required'),
        start_date: yup.string().required('Start Date is required'),
        end_date: yup.string().required('End Date is required'),
        agenda: yup.string().required('Agenda is required'),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            type: "",
            location: "",
            category: [""],
            slots: "",
            price: "",
            start_date: "",
            end_date: "",
            agenda: "",
        },
        validationSchema: eventSchema,
        onSubmit: async (formData) => {
            setError("");
            setCreateEventLoading(true);

            const startDateUTC = new Date(formData.start_date).toISOString();
            const endDateUTC = new Date(formData.end_date).toISOString();

            const event_params = {
                name: formData.name,
                type: formData.type,
                location: formData.location,
                category: formData.category,
                slots: formData.slots,
                price: formData.price,
                start_date: startDateUTC,
                end_date: endDateUTC,
                agenda: formData.agenda,
            };

            dispatch(auth_actions_helper(loading_create_training_event, success_create_training_event, fail_create_training_event, CREATE_TRAINING_EVENT_ERR_MSG, CREATE_TRAINING_EVENT_SUCCESS_MSG, app_consts.POST, CREATE_TRAINING_EVENT_API, event_params));
        },
    });

    const create_training_event_listener = useSelector((state) => state.create_training_event?.create_training_event);

    useEffect(() => {
        if (create_training_event_listener?.status !== app_consts.IDLE) {
            if (create_training_event_listener?.status === app_consts.LOADING) {
                setCreateEventLoading(true);
            } else {
                if (create_training_event_listener?.status === app_consts.FAIL) {
                    setError(create_training_event_listener?.message);
                } else if (create_training_event_listener?.status === app_consts.SUCCESS) {
                    formik.resetForm();
                    successFunction();
                    toggle();
                }

                dispatch(reset_create_training_event());
                setCreateEventLoading(false);
            }
        }
    }, [create_training_event_listener, reset_create_training_event]);

    const categoryOptions = [
        { value: 'category1', label: 'Category 1' },
        { value: 'category2', label: 'Category 2' },
        { value: 'category3', label: 'Category 3' },
    ];

    const handleCategoryChange = (selected) => {
        const categories = selected ? selected.map((option) => option.value) : [];
        formik.setFieldValue("category", categories);
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            centered
            size="lg"
        >
            <ModalHeader toggle={toggle}>
                Create Event
            </ModalHeader>
            <ModalBody>
                <Form>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="name">
                                    Name <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    invalid={formik.touched.name && !!formik.errors.name}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <FormFeedback>{formik.errors.name}</FormFeedback>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="type">
                                    Type <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="select"
                                    name="type"
                                    id="type"
                                    value={formik.values.type}
                                    onChange={formik.handleChange}
                                    invalid={formik.touched.type && !!formik.errors.type}
                                >
                                    <option value="">Select Type</option>
                                    <option value="virtual">Virtual</option>
                                    <option value="physical">Physical</option>
                                </Input>
                                {formik.touched.type && formik.errors.type && (
                                    <FormFeedback>{formik.errors.type}</FormFeedback>
                                )}
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="location">
                                    {formik.values.type === "virtual"
                                        ? "Meeting Link (Zoom/MS Teams, etc)"
                                        : "Location"}
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    name="location"
                                    id="location"
                                    value={formik.values.location}
                                    onChange={formik.handleChange}
                                    invalid={formik.touched.location && !!formik.errors.location}
                                />
                                {formik.touched.location && formik.errors.location && (
                                    <FormFeedback>{formik.errors.location}</FormFeedback>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="category">
                                    Category <span className="text-danger">*</span>
                                </Label>
                                <Select
                                    isMulti
                                    options={categoryOptions}
                                    value={categoryOptions.filter((option) => formik.values.category.includes(option.value))}
                                    onChange={handleCategoryChange}
                                    placeholder="Select categories"
                                />
                                {formik.touched.category && formik.errors.category && (
                                    <div className="text-danger">{formik.errors.category}</div>
                                )}
                            </FormGroup>

                        </Col>
                    </Row>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="slots">
                                    Slots <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="number"
                                    name="slots"
                                    id="slots"
                                    value={formik.values.slots}
                                    onChange={formik.handleChange}
                                    invalid={formik.touched.slots && !!formik.errors.slots}
                                />
                                {formik.touched.slots && formik.errors.slots && (
                                    <FormFeedback>{formik.errors.slots}</FormFeedback>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="price">
                                    Price <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="number"
                                    name="price"
                                    id="price"
                                    value={formik.values.price}
                                    onChange={formik.handleChange}
                                    invalid={formik.touched.price && !!formik.errors.price}
                                />
                                {formik.touched.price && formik.errors.price && (
                                    <FormFeedback>{formik.errors.price}</FormFeedback>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="start_date">
                                    Start Date <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="datetime-local"
                                    name="start_date"
                                    id="start_date"
                                    value={formik.values.start_date}
                                    onChange={formik.handleChange}
                                    invalid={formik.touched.start_date && !!formik.errors.start_date}
                                />
                                {formik.touched.start_date && formik.errors.start_date && (
                                    <FormFeedback>{formik.errors.start_date}</FormFeedback>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="end_date">
                                    End Date <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="datetime-local"
                                    name="end_date"
                                    id="end_date"
                                    value={formik.values.end_date}
                                    onChange={formik.handleChange}
                                    invalid={formik.touched.end_date && !!formik.errors.end_date}
                                />
                                {formik.touched.end_date && formik.errors.end_date && (
                                    <FormFeedback>{formik.errors.end_date}</FormFeedback>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="agenda">
                                    Agenda <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="textarea"
                                    name="agenda"
                                    id="agenda"
                                    value={formik.values.agenda}
                                    onChange={formik.handleChange}
                                    invalid={formik.touched.agenda && !!formik.errors.agenda}
                                />
                                {formik.touched.agenda && formik.errors.agenda && (
                                    <FormFeedback>{formik.errors.agenda}</FormFeedback>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                    {error && <Alert color="danger">{error}</Alert>}
                    <Col lg="12" className="d-flex justify-content-end align-items-end mt-4">
                        <Button color="success" onClick={formik.handleSubmit} disabled={createEventLoading}>
                            {createEventLoading ? "Creating Event" : "Create Event"}
                        </Button>
                    </Col>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default CreateTrainingEventModal;
