import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as app_consts from '../../../utils/constants';
import { no_auth_actions_helper } from '../../../actions/actions_helper';
import { Button, Card, Col, Container, Row, Form, Label, Input, FormFeedback, Spinner, Alert } from 'reactstrap';
import AuthSlider from '../authCarousel';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { fail_auth_change_password, loading_auth_change_password, reset_auth_change_password, success_auth_change_password } from '../../../reducers/public_reducers/auth/auth_change_password_slice';
import { CHANGE_PASSWORD_ERR_MSG, CHANGE_PASSWORD_SUCCESS_MSG } from '../../../utils/response_messages_constants';
import { CHANGE_PASSWORD_API } from '../../../api/api_endpoints';

const CoverPasswCreate = () => {
    const { email, otp } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [loadingMessage, setloadingMessage] = useState("");

    document.title = "Create New Password | TradeEase Integrated Digital Platform";

    const [passwordShow, setPasswordShow] = useState(false);
    const [confrimPasswordShow, setConfrimPasswordShow] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            password: "",
            confirm_password: "",
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(6, 'Password must be at least 6 characters')
                .matches(RegExp('(.*[0-9].*)'), 'At least one number')
                .required("This field is required"),
            confirm_password: Yup.string()
                .oneOf([Yup.ref("password")], "Both password need to be the same")
                .required("Confirm Password Required"),
        }),
        
        onSubmit: (values) => {

            const params = {
                email: email,
                otp: otp,
                new_password: values.password,
                confirm_new_password: values.confirm_password
            }
            dispatch(no_auth_actions_helper(loading_auth_change_password, success_auth_change_password, fail_auth_change_password, CHANGE_PASSWORD_ERR_MSG, CHANGE_PASSWORD_SUCCESS_MSG, app_consts.POST, CHANGE_PASSWORD_API, params));
        }
    });

    const change_password_listener = useSelector((state) => state.auth_change_password.auth_change_password);
console.log(change_password_listener);

    useEffect(() => {
        if (change_password_listener.status !== app_consts.IDLE) {
            if (change_password_listener.status === app_consts.LOADING) {
                setLoading(true);
                setloadingMessage("Resetting Password...");
            }
            else {
                if (change_password_listener.status === app_consts.FAIL) {
                    setError(change_password_listener.message);
                    dispatch(reset_auth_change_password());
                    setLoading(false);
                }
                else if (change_password_listener.status === app_consts.SUCCESS) {
                    navigate('login')
                }
            }
        }
    }, [change_password_listener]);

    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden">
                                    <Row className="justify-content-center g-0">
                                        <AuthSlider />
                                        <Col lg={6}>
                                            <div className="p-lg-5 p-4">
                                                <h5 className="text-primary">Create new password</h5>
                                                <p className="text-muted">Your new password must be different from previous used password.</p>
                                                {error && (
                                                    <>
                                                        <Alert color="danger">
                                                            <div className='d-flex flex-row align-items-center'>
                                                                <i className='ri-error-warning-line fs-16' />
                                                                <div className='mx-2'>{error}</div>
                                                            </div>
                                                        </Alert>
                                                    </>
                                                )}
                                                <div className="p-2">
                                                    <Form onSubmit={validation.handleSubmit} action="/login">
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="password-input">Password</Label>
                                                            <div className="position-relative auth-pass-inputgroup">
                                                                <Input
                                                                    type={passwordShow ? "text" : "password"}
                                                                    className="form-control pe-5 password-input"
                                                                    placeholder="Enter password"
                                                                    id="password-input"
                                                                    name="password"
                                                                    value={validation.values.password}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={validation.handleChange}
                                                                    invalid={validation.errors.password && validation.touched.password ? true : false}
                                                                />
                                                                {validation.errors.password && validation.touched.password ? (
                                                                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                                ) : null}
                                                                <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                                    id="password-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                            </div>
                                                            <div id="passwordInput" className="form-text">Must be at least 6 characters.</div>
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="confirm-password-input">Confirm Password</Label>
                                                            <div className="position-relative auth-pass-inputgroup mb-3">
                                                                <Input
                                                                    type={confrimPasswordShow ? "text" : "password"}
                                                                    className="form-control pe-5 password-input"
                                                                    placeholder="Confirm password"
                                                                    id="confirm-password-input"
                                                                    name="confirm_password"
                                                                    value={validation.values.confirm_password}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={validation.handleChange}
                                                                    invalid={validation.errors.confirm_password && validation.touched.confirm_password ? true : false}
                                                                />
                                                                {validation.errors.confirm_password && validation.touched.confirm_password ? (
                                                                    <FormFeedback type="invalid">{validation.errors.confirm_password}</FormFeedback>
                                                                ) : null}
                                                                <Button color="link" onClick={() => setConfrimPasswordShow(!confrimPasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon">
                                                                    <i className="ri-eye-fill align-middle"></i></Button>
                                                            </div>
                                                        </div>

                                                        <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                                            <h5 className="fs-13">Password must contain:</h5>
                                                            <p id="pass-length" className="invalid fs-12 mb-2">Minimum <b>6 characters</b></p>
                                                            <p id="pass-lower" className="invalid fs-12 mb-2">At <b>lowercase</b> letter (a-z)</p>
                                                            <p id="pass-upper" className="invalid fs-12 mb-2">At least <b>uppercase</b> letter (A-Z)</p>
                                                            <p id="pass-number" className="invalid fs-12 mb-0">A least <b>number</b> (0-9)</p>
                                                        </div>

                                                        <div className="form-check">
                                                            <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                            <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                                        </div>

                                                        <div className="mt-4">

                                                            <Button color="success" disabled={error ? null : loading ? true : false} className="btn btn-success w-100 flex flex-row" type="submit">
                                                        {
                                                            loading ? <><Spinner size="sm" className='me-2' />  {loadingMessage} </> : "Reset Password"
                                                        }
                                                    </Button>
                                                        </div>
                                                    </Form>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p className="mb-0">I remember my password... <Link to="/auth-signin-cover" className="fw-semibold text-primary text-decoration-underline"> Click here </Link> </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CoverPasswCreate;


// branch is not a function when I enter the number
