import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SUCCESS } from '../utils/constants';
import * as app_consts from './constants';
import { useNavigate } from 'react-router-dom';

// Convert UnderScore to Title Case
export const convertUnderscoreToTitleCase = (inputString) => {
  // Split the input string into words based on underscores
  const words = inputString.split('_');

  // Capitalize the first letter of each word and join them with a space
  const result = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return result;
};

export const formatNumber = (number) => {
  const numberFormat = new Intl.NumberFormat('en-ZM', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return numberFormat.format(number);
};

// Add Three Dots
export const addThreeDots = (str, strLength) => {
  if (str)
  {
    if (str.length > strLength)
    {
      return str.slice(0, strLength) + '...';
    }
  }
  return str;
};

// Function to Capitalize Every First Letter of a String
export const capitalizeSentences = (inputString) => {
  // Split the input string into sentences using period as the delimiter
  var sentences = inputString.split('.');

  // Capitalize the first letter of each sentence
  for (var i = 0; i < sentences.length; i++)
  {
    sentences[i] = sentences[i].trim(); // Remove leading/trailing spaces
    if (sentences[i])
    {
      sentences[i] = sentences[i][0].toUpperCase() + sentences[i].slice(1);
    }
  }

  // Join the sentences back into a string
  var result = sentences.join('. ');

  return result;
};

// Function to Get Entity Profile Prefix
export const retrieveEntityProfileSuffix = (value) => {
  if (value === 'warehouse_operator')
  {
    return app_consts.WAREHOUSE_OPERATOR_SUFFIX;
  } else if (value === 'broker')
  {
    return app_consts.BROKER_SUFFIX;
  } else if (value === 'cooperative')
  {
    return app_consts.COOPERATIVE_SUFFIX;
  } else if (value === 'service_provider')
  {
    return app_consts.SERVICE_PROVIDER_SUFFIX;
  } else if (value === 'investor')
  {
    return app_consts.INVESTOR_SUFFIX;
  } else if (value === 'financial_institution')
  {
    return app_consts.FINANCIAL_INSTITUTION_SUFFIX;
  } else if (value === 'exchange')
  {
    return app_consts.EXCHANGE_SUFFIX;
  }
};

// Convert to Title Case
export const convertToTitleCase = (str) => {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// Remove Number and Replace with ' / '.
export const replace_string_with_forward_slash = (inputString) => {
  const replaced_string = inputString.split('1').join(` / `);
  return replaced_string;
};

// Mapping Data to Go in RSelect fields
export const retreive_rSelectVariables = (mapType, data) => {
  let label;
  let value;
  //   Warehouse Map
  if (mapType === app_consts.WAREHOUSES_MAP)
  {
    label = `${data.warehouse_id} - ${data.name}`;
    value = data.warehouse_id;
  }
  // Producers List Map
  else if (mapType === app_consts.PRODUCERS_LIST_MAP)
  {
    if (data?.type !== app_consts.INDIVIDUAL.toLowerCase()) 
    {
      label = `${data.producer_id} - ${data.entity_name}`;
      value = data.id;
    }
    else
    {
      label = `${data.producer_id} - ${data.contact_name}`;
      value = data.id;
    }
    // Pedning Entity List Map
  } else if (mapType === app_consts.PENDING_ENTITY_LIST_MAP)
  {
    label = `${data.entity_name} - ${data.entity_email_address}`;
    value = data.id;
  } else if (mapType === app_consts.SERVICE_PROVIDER_LIST_MAP)
  {
    label = `${data.entity_mid} - ${data.entity_name}`;
    value = data.id;
  } else if (mapType === app_consts.AVAILABLE_STORAGE_LIST_MAP)
  {
    label = `${convertToTitleCase(replace_string_with_forward_slash(data.storage_type))}.  (${parseFloat(data.available_space || 0).toFixed(2)} MT Available)`;
    value = data.storage_type;
  } else if (mapType === app_consts.WAREHOUSES_MAP_VR_LIST)
    {
      {
        label = `${data.warehouse_id} - ${data.name}`;
        value = data.id;
      }
    } else if (mapType === app_consts.ENTITY_LIST_MAP)
    {
      label = `${data.organization_id} - ${data.entity_name}`;
      value = data.id;
    } else if (mapType === app_consts.ACTIVE_BROKER_LIST_MAP)
    {
      label = `${data.member_type_id} - ${data.entity_name
        }`;
      value = data.member_type_id;
    } 
    else if (mapType === app_consts.WAREHOUSES_PR_CREATE_MAP)
    {
      label = `${data.warehouse_id} - ${data.name}`;
      value = data.id;
    }
    else if (mapType === app_consts.ATS_PRODUCERS_LIST_MAP)      
      {
        if (data?.producer?.type !== app_consts.INDIVIDUAL.toLowerCase()) 
        {
          label = `${data?.producer?.producer_id} - ${data?.producer_entity?.producer_entity_name}`;
          value = data?.producer?.id;
        }
        else
        {
          label = `${data?.producer?.producer_id} - ${data.producer.contact_name}`;
          value = data?.producer?.id;
        }
    }
  return { label, value };
};

// Mapping Data for RSelect
export const rSelectMapFun = async (label, value) => {
  return {
    label: label,
    value: value,
  };
};

// ---------------------------- Toast Messages ---------------------------
export const messageToast = (message, type) => {
  if (type === app_consts.SUCCESS)
  {
    toast.success(message, {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: true,
      theme: "light",
      autoClose: 5000,
      progress: undefined,
    });
  } else if (type === app_consts.FAIL)
  {
    toast.error(message, {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: true,
      theme: "light",
      autoClose: 5000,
      progress: undefined,
    });
  }
};

// Redirect to Necessary Page
export const route_page_redirections = () => {

  const checkToken = (localStorage.getItem('authToken'));

  if (checkToken) 
  {
    const account = localStorage.getItem('account') ? JSON.parse(localStorage.getItem('account')) : {};
    const hasProfile = account.profiles && account.profiles.length > 0;
    const pendingProfile = hasProfile ? account.profiles.find((profile) => profile.status === 'pending' && profile.account_id === account.id) : undefined;
    const contactInfo = account.profiles.find((profile) => profile.entity_kyc_set === 1 && profile.account_id === account.id);
    const registrationCertificateProfile = account.profiles.find((profile) => profile.entity_reg_uploaded === 1 && profile.account_id === account.id);
    const registrationTaxCertificateProfile = account.profiles.find((profile) => profile.entity_tax_uploaded === 1 && profile.account_id === account.id);
    const pacraPrintOutProfile = account.profiles.find((profile) => profile.entity_pacra_printout_uploaded === 1 && profile.account_id === account.id);
    const registrationCompanyProfile = account.profiles.find((profile) => profile.entity_profile_uploaded === 1 && profile.account_id === account.id);
    const registrationBoardResolution = account.profiles.find((profile) => profile.entity_resolution_uploaded === 1 && profile.account_id === account.id);
    const registrationDirectorDetails = account.profiles.find((profile) => profile.entity_owners_uploaded === 1 && profile.account_id === account.id);
    const registrationBankDetails = account.profiles.find((profile) => profile.bank_account_set === 1 && profile.account_id === account.id);
    const registrationDeclaration = account.profiles.find((profile) => profile.bank_account_set === 1 && profile.entity_set !== 1 && profile.account_id === account.id);
    const registrationVerification = account.profiles.find((profile) => profile.entity_set === 0 && profile.account_id === account.id);
    const zamaceProfile = account.profiles.find(profile => profile.type === "exchange");
    const brokerProfile = account.profiles?.find(profile => profile.type === "broker");
    const firstAccount = account.profiles?.[0].type;

    let nextPage = null;
    /* if (firstAccount)
    {
      nextPage = `/${firstAccount}`;
    } else  */if (hasProfile && account.active === 1)
    {
      nextPage = '/dashboard';
    } else if (account.verified === 0)
    {
      nextPage = '/verify_account';
    } else if (!hasProfile)
    {
      nextPage = '/membership_type';
    } else if (hasProfile && pendingProfile)
    {
      if (hasProfile && !contactInfo)
      {
        nextPage = '/complete_entity_kyc';
      } else if (hasProfile && !registrationCertificateProfile)
      {
        nextPage = '/registration_certificate';
      } else if (hasProfile && contactInfo && registrationCertificateProfile && !registrationTaxCertificateProfile)
      {
        nextPage = '/registration_tax_certificate';
      }
      else if (hasProfile && contactInfo && registrationCertificateProfile && registrationTaxCertificateProfile && !pacraPrintOutProfile)
      {
        nextPage = '/registration_pacra_printout';
      }
      else if (
        hasProfile &&
        contactInfo &&
        registrationCertificateProfile &&
        registrationTaxCertificateProfile &&
        pacraPrintOutProfile &&
        !registrationCompanyProfile
      )
      {
        nextPage = '/organization_profile';
      } else if (
        hasProfile &&
        contactInfo &&
        registrationCertificateProfile &&
        registrationTaxCertificateProfile &&
        pacraPrintOutProfile &&
        registrationCompanyProfile &&
        !registrationBoardResolution
      )
      {
        nextPage = '/board_resolution_document';
      } else if (
        hasProfile &&
        contactInfo &&
        registrationCertificateProfile &&
        registrationTaxCertificateProfile &&
        pacraPrintOutProfile &&
        registrationCompanyProfile &&
        registrationBoardResolution &&
        !registrationDirectorDetails
      )
      {
        nextPage = '/director_details';
      } else if (
        hasProfile &&
        contactInfo &&
        registrationCertificateProfile &&
        registrationTaxCertificateProfile &&
        pacraPrintOutProfile &&
        registrationCompanyProfile &&
        registrationBoardResolution &&
        registrationDirectorDetails &&
        !registrationBankDetails
      )
      {
        nextPage = '/bank_details';
      } else if (
        hasProfile &&
        contactInfo &&
        registrationCertificateProfile &&
        registrationTaxCertificateProfile &&
        pacraPrintOutProfile &&
        registrationCompanyProfile &&
        registrationBoardResolution &&
        registrationDirectorDetails &&
        registrationBankDetails &&
        !registrationDeclaration
      )
      {
        nextPage = '/registration_declaration';
      } else if (
        hasProfile &&
        contactInfo &&
        registrationCertificateProfile &&
        registrationTaxCertificateProfile &&
        pacraPrintOutProfile &&
        registrationCompanyProfile &&
        registrationBoardResolution &&
        registrationDirectorDetails &&
        registrationBankDetails &&
        registrationDeclaration &&
        registrationVerification
      )
      {
        nextPage = '/registration_verification';
      }
    }
    if (nextPage)
    {
      window.history.pushState({}, '', nextPage);
    }
  }
};