import React, { useEffect, useState, useCallback, useMemo } from "react";
import classnames from "classnames";
import * as app_consts from "../../utils/constants";
import { Col, Container, Row, Card, CardHeader, CardBody, Button } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";
import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RETRIEVE_EVENT_LIST_ERR_MSG, RETRIEVE_EVENT_LIST_SUCCESS_MSG } from "../../utils/response_messages_constants";
import { RETRIEVE_EVENT_LIST_API, RETRIEVE_OUTBOUND_SHIPMEMNT_LIST_API } from "../../api/api_endpoints";
import { auth_actions_helper } from "../../actions/actions_helper";
import { format } from "date-fns";
import { fail_event_list, loading_event_list, reset_event_list, success_event_list } from "../../reducers/private_reducers/events/event_list_slice";
import CountUp from "react-countup";
import { ticketsWidgets } from "../../common/data/widgetData";

const TicketManagement = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [outbound_shipments, setEventList] = useState([]);

    const account = localStorage.getItem('account') ? JSON.parse(localStorage.getItem('account')) : {};
    const warehouseProfile = account.profiles?.find(profile => profile.type === "warehouse_operator");

    useEffect(() => {
        dispatch(auth_actions_helper(loading_event_list, success_event_list, fail_event_list, RETRIEVE_EVENT_LIST_ERR_MSG, RETRIEVE_EVENT_LIST_SUCCESS_MSG, app_consts.GET, RETRIEVE_EVENT_LIST_API));
    }, []);

    const SuccessFunction = () => {
        dispatch(auth_actions_helper(loading_event_list, success_event_list, fail_event_list, RETRIEVE_EVENT_LIST_ERR_MSG, RETRIEVE_EVENT_LIST_SUCCESS_MSG, app_consts.GET, RETRIEVE_EVENT_LIST_API));
    };

    const event_list_listener = useSelector((state) => state.event_list?.event_list);

    useEffect(() => {
        if (event_list_listener?.status !== app_consts.IDLE) {
            if (event_list_listener?.status === app_consts.LOADING) {
                setLoading(true);
            } else {
                if (event_list_listener?.status === app_consts.FAIL) {
                    setError(event_list_listener?.message);
                } else if (event_list_listener?.status === app_consts.SUCCESS) {
                    setEventList(event_list_listener?.data);
                }
                reset_event_list();
                setLoading(false);
            }
        }
    }, [event_list_listener, reset_event_list]);

    const [modalOpen, setModalOpen] = useState(false);
    const [outboundData, setOutboundData] = useState(null);

    const toggleModal = (outboundData) => {
        setOutboundData(outboundData);
        setModalOpen(!modalOpen);
    };

    const [createModalOpen, setCreateModalOpen] = useState(false);

    const toggleCreateModal = () => {
        setCreateModalOpen(!createModalOpen);
    };

    const Widgets = () => {
        return (
            <React.Fragment>
                {(ticketsWidgets || []).map((item, key) => (
                    <Col xxl={3} sm={6} key={key}>
                        <Card className="card-animate">
                            <CardBody>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p className="fw-medium text-muted mb-0">{item.title}</p>
                                        <h2 className="mt-4 ff-secondary fw-semibold">
                                            <span className="counter-value" data-target="547">
                                                <CountUp
                                                    start={0}
                                                    end={item.counter}
                                                    duration={3}
                                                    suffix={item.suffix}
                                                    prefix={item.prefix}
                                                    decimals={item.decimals}
                                                />
                                            </span>
                                        </h2>
                                        {/* <p className="mb-0 text-muted"><span className={"badge bg-light text-" + item.percentageClass + " mb-0"}>
                                            <i className={item.arrowIcon + " align-middle"}></i> {item.percentage}
                                        </span> vs. previous month</p> */}
                                    </div>
                                    <div>
                                        <div className="avatar-sm flex-shrink-0">
                                            <span
                                                className={`avatar-title rounded-circle fs-4 
                                                    ${item?.status === 'total' ? 'bg-soft-info text-info' : ''} 
                                                    ${item?.status === 'active' ? 'bg-soft-success text-success' : ''} 
                                                    ${item?.status === 'pending' ? 'bg-soft-warning text-warning' : ''} 
                                                    ${item?.status === 'closed' ? 'bg-soft-danger text-danger' : ''}`
                                                }>
                                                <i className={item.icon}></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </React.Fragment>
        );
    };

    const columns = useMemo(
        () => [
            {
                Header: "TICKET ID",
                accessor: "outbound_shipment.outbound_type",
                filterable: false,
                Cell: (contact) => (
                    <>
                        <div className="d-flex align-items-center">
                            {contact.row.original?.outbound_shipment?.outbound_type?.replace(/_/g, ' ').toUpperCase()}
                        </div>
                    </>
                ),
            },
            {
                Header: "TITLE",
                accessor: "outbound_shipment.outbound_commodity",
                filterable: false,
                Cell: (contact) => (
                    <>
                        <div className="d-flex align-items-center">
                            {contact.row.original?.outbound_shipment?.outbound_commodity?.replace(/_/g, ' ').toUpperCase()}
                        </div>
                    </>
                ),
            },
            {
                Header: "USER",
                accessor: "outbound_shipment.total_quantity",
                filterable: false,
                Cell: (contact) => (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 ms-2 name">
                                {contact.row.original?.outbound_shipment?.total_quantity}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "ASSIGNED TO",
                accessor: "warehouse.warehouse_id",
                filterable: false,
                Cell: (outbound) => (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 ms-2 name">
                                {outbound.row.original?.warehouse?.warehouse_id}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "DATE CREATED",
                accessor: "outbound_shipment.outbound_commodity_data.transfer_no",
                filterable: false,
                Cell: (outbound) => (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 ms-2 name">
                                {outbound.row.original?.outbound_shipment?.outbound_commodity_data?.transfer_no}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "PRIORITY",
                accessor: "priority",
                filterable: false,
                Cell: (contact) => (
                    <>
                        <div className="d-flex align-items-center">
                            {contact.row.original.priority === "Medium" ?
                                <span className="badge bg-warning text-uppercase">{contact.row.original.priority}</span>
                                : contact.row.original.priority === "High" ?
                                    <span className="badge bg-danger text-uppercase">{contact.row.original.priority}</span>
                                    : contact.row.original.priority === "Low" ?
                                        <span className="badge bg-success text-uppercase">{contact.row.original.priority}</span>
                                        : null
                            }
                        </div>
                    </>
                ),
            },
            {
                Header: "STATUS",
                accessor: "status",
                filterable: false,
                Cell: (contact) => (
                    <>
                        <div className="d-flex align-items-center">
                            {contact.row.original?.ticket?.status === "Inprogress" ? <span className="badge badge-soft-warning text-uppercase">{contact.row.original?.ticket?.status.replace(/_/g, ' ').toUpperCase()}</span>
                                : contact.row.original?.ticket?.status === "New" ? <span className="badge badge-soft-info text-uppercase">{contact.row.original?.ticket?.status.replace(/_/g, ' ').toUpperCase()}</span>
                                    : contact.row.original?.ticket?.status === "Open" ? <span className="badge badge-soft-success text-uppercase">{contact.row.original?.ticket?.status.replace(/_/g, ' ').toUpperCase()}</span>
                                        : contact.row.original?.ticket?.status === "Closed" ? <span className="badge badge-soft-danger text-uppercase">{contact.row.original?.ticket?.status.replace(/_/g, ' ').toUpperCase()}</span>
                                            : null
                            }
                        </div>
                    </>
                ),
            },
            {
                Header: "ACTION",
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0">
                            <li className="list-inline-item">
                                <Button color="light" onClick={() => toggleModal(cellProps?.row?.original)}
                                >{" "}View</Button>
                            </li>
                        </ul>
                    );
                },
            },
        ],
        []
    );


    document.title = "Ticket Management | TradeEase Integrated Digital Platform";
    return (
        <React.Fragment>
            <div className="page-content">

                {/* <CreateOutboundShipmentModal isOpen={createModalOpen} toggle={() => toggleCreateModal()} id={null} successFunction={createOutboundSuccess} /> */}
                {/* <ViewSpecificOutboundShipmentDetailsModal isOpen={modalOpen} toggle={() => toggleModal()} outboundData={outboundData} /> */}
                <Container fluid>
                    <BreadCrumb title="Ticket Management" pageTitle="Home" />
                    <Row>
                        <Widgets/>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <button
                                            className="btn btn-success"
                                            onClick={() => {
                                                toggleCreateModal();
                                            }}
                                        >
                                            <i className="ri-add-fill me-1 align-bottom"></i>
                                            Create Ticket
                                        </button>
                                    </div>
                                </CardHeader>
                            </Card>
                        </Col>
                        <Col lg={12}>
                            <Card id="contactList">
                                <CardBody className="pt-0">
                                    <div>
                                        {loading ? (
                                            <Loader
                                            loader_type={app_consts.LOGO_LOADER}
                                            loading_message={"Loading Tickets..."} />
                                        ) : error ? (
                                            <Loader error={error} />
                                        ) : success_event_list && outbound_shipments?.length ? (
                                            <TableContainer
                                                columns={columns}
                                                data={outbound_shipments || []}
                                                isGlobalFilter={true}
                                                isAddUserList={false}
                                                customPageSize={10}
                                                className="custom-header-css"
                                                divClass="table-responsive table-card mb-3"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="table-light"
                                                // handleContactClick={handleContactClicks}
                                                isContactsFilter={true}
                                                SearchPlaceholder="Search..."
                                            />
                                        ) : (
                                            <div className="d-flex justify-content-center align-items-center">
                                                No Data Available
                                            </div>
                                        )}
                                    </div>

                                    <ToastContainer closeButton={false} limit={1} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TicketManagement;
