import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Button
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";
import Loader from "../../Components/Common/Loader";
// import { format } from 'date-fns';
import { auth_actions_helper } from "../../actions/actions_helper";
import { RETRIEVE_USER_LIST_API } from "../../api/api_endpoints";
import { RETRIEVE_USER_LIST_ERR_MSG, RETRIEVE_USER_LIST_SUCCESS_MSG } from "../../utils/response_messages_constants";
import * as app_consts from "../../utils/constants";
import { useParams } from "react-router-dom";
import { fail_user_list, loading_user_list, reset_user_list, success_user_list } from "../../reducers/private_reducers/user_list_slice";
import ViewEntityDetailsModal from "../../Components/Modals/ViewEntityDetailsModal";
import CountUp from "react-countup";
import { userWidgetsData } from "../../common/data/widgetData";

const UserManagement = () => {
  const dispatch = useDispatch();
  const [userListData, setUserListData] = useState([]);
    
  useEffect(() => {
    dispatch(auth_actions_helper(loading_user_list, success_user_list, fail_user_list, RETRIEVE_USER_LIST_ERR_MSG, RETRIEVE_USER_LIST_SUCCESS_MSG, app_consts.GET, RETRIEVE_USER_LIST_API));
  }, []);

  const user_list_listener = useSelector((state) => state.user_list.user_list);
  
  useEffect(() => {
    if (user_list_listener.status !== app_consts.IDLE) {
      if (user_list_listener.status === app_consts.LOADING) {
        setLoading(true);
      }
      else {
        if (user_list_listener.status === app_consts.FAIL) {
          setError(user_list_listener.message);
        }
        else if (user_list_listener.status === app_consts.SUCCESS) {
          setUserListData(user_list_listener?.data);
        }
        dispatch(reset_user_list());
        setLoading(false);
      }
    }
  }, [user_list_listener]);

  const successFunction = () => {
    dispatch(auth_actions_helper(loading_user_list, success_user_list, fail_user_list, RETRIEVE_USER_LIST_ERR_MSG, RETRIEVE_USER_LIST_SUCCESS_MSG, app_consts.GET, RETRIEVE_USER_LIST_API));
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [organizationID, setOrganizationId] = useState('');

  const [viewDetailsModal, setViewDetailsModal] = useState(false);

  const onClickViewDetails = (organization_id) => {   
    setOrganizationId(organization_id);
    setViewDetailsModal(true);
    };

    // console.log("userListData: ", userListData);
    
    const UserStatsWidgets = ({widgetsData}) => {
      return (
          <React.Fragment>
              {(widgetsData || []).map((item, key) => (
                
                <Col xxl={3} sm={6} key={key}>
                  {/* {console.log("item: ", item)} */}
                      <Card className="card-animate">
                          <CardBody>
                              <div className="d-flex justify-content-between">
                                  <div>
                                      <p className="fw-medium text-muted mb-0">{item.title}</p>
                                      <h2 className="mt-4 ff-secondary fw-semibold">
                                          <span className="counter-value" data-target="547">
                                              <CountUp
                                                  start={0}
                                                  end={item.counter}
                                                  duration={3}
                                                  suffix={item.suffix}
                                                  prefix={item.prefix}
                                                  decimals={item.decimals}
                                              />
                                          </span>
                                      </h2>
                                      {/* <p className="mb-0 text-muted"><span className={"badge bg-light text-" + item.percentageClass + " mb-0"}>
                                          <i className={item.arrowIcon + " align-middle"}></i> {item.percentage}
                                      </span> vs. previous month</p> */}
                                  </div>
                                  <div>
                                      <div className="avatar-sm flex-shrink-0">
                                          <span
                                              className={`avatar-title rounded-circle fs-4 
                                                  ${item?.status === 'total' ? 'bg-soft-info text-info' : ''} 
                                                  ${item?.status === 'active' ? 'bg-soft-success text-success' : ''} 
                                                  ${item?.status === 'pending_verification' ? 'bg-soft-warning text-warning' : ''} 
                                                  ${item?.status === 'closed' ? 'bg-soft-danger text-danger' : ''}`
                                              }>
                                              <i className={item.icon}></i>
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </CardBody>
                      </Card>
                  </Col>
              ))}
          </React.Fragment>
      );
  };

  const widgetsData = userWidgetsData(userListData);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "entity.organization_id",
        filterable: false,
        Cell: (user) => (
          <>
            <div className="d-flex align-items-center">
            {user.row.original?.entity?.organization_id || '---'}
            </div>
          </>
        ),
      },
      {
        Header: "NAME",
        accessor: "user?.first_name",
        filterable: false,
        Cell: (user) => (
          <>
            <div className="d-flex align-items-center">
            {user.row.original?.user?.first_name || '---'} {user.row.original?.user?.last_name || ''}
            </div>
          </>
        ),
      },
      {
        Header: "PHONE NUMBER",
        accessor: "account?.mobile_number",
        filterable: false,
        Cell: (user) => (
          <>
            <div className="d-flex align-items-center">
              {user.row.original?.account?.mobile_number}
            </div>
          </>
        ),
      },
      {
        Header: "TYPE",
        accessor: "entity?.entity_type",
        filterable: false,
        Cell: (user) => (
          <>
            <div className="d-flex align-items-center">
              {user.row.original?.entity?.entity_type?.replace(/_/g, ' ').toUpperCase() || "---"}
            </div>
          </>
        ),
      },
      {
        Header: "CATEGORY",
        accessor: "entity?.entity_category",
        filterable: false,
        Cell: (user) => (
            <>
              <div className="d-flex align-items-center">
                {user.row.original?.entity?.entity_category?.replace(/_/g, ' ').toUpperCase() || "---"}
              </div>
            </>
          ),
      },
      {
        Header: "STATUS",
        accessor: "entity?.status",
        filterable: false,
        Cell: (user) => (
          <>
            <div className="d-flex align-items-center">
              {user.row?.original?.entity?.status === "pending_verification" ? <span className="badge badge-soft-warning text-uppercase">{user.row?.original?.entity?.status?.replace(/_/g, " ").toUpperCase()}</span>
                : user.row?.original?.entity?.status === "new" ? <span className="badge badge-soft-info text-uppercase">{user.row?.original?.entity?.status?.replace(/_/g, " ").toUpperCase()}</span>
                  : user.row?.original?.entity?.status === "active" ? <span className="badge badge-soft-success text-uppercase">{user.row?.original?.entity?.status?.replace(/_/g, " ").toUpperCase()}</span>
                    : user.row?.original?.entity?.status === "closed" ? <span className="badge badge-soft-danger text-uppercase">{user.row?.original?.entity?.status?.replace(/_/g, " ").toUpperCase()}</span>
                      : null
              }
            </div>
          </>
        ),
      },
      {
        Header: "ACTION",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <Button color="light" 
              onClick={() => onClickViewDetails(cellProps.row.original?.entity?.organization_id)}

              >{" "}View
              </Button>
            </ul>
          );
        },
      },
    ],
  );

  document.title = "User Management | TradeEase Integrated Digital Platform";

  return (
    <React.Fragment>
      <div className="page-content">
        <ViewEntityDetailsModal isOpen={viewDetailsModal} toggle={() => setViewDetailsModal(false)} organization_id={organizationID} id={'1'}/>
        <Container fluid>
          <BreadCrumb title="User Management" pageTitle="Home" />
          <Row>
            <UserStatsWidgets widgetsData={widgetsData}/>
            <Col lg={12}>
              <Card>
              {/* <CardHeader className="border-0">
              <div className="d-flex align-items-center flex-wrap gap-2">
              <h5 className="card-title mb-0 flex-grow-1">Users ({userListData?.length})</h5>
                    <div className="flex-grow-1">
                    </div>
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                      <button
                        className="btn btn-success add-btn"
                        onClick={() => {
                        //   setCreateClientDetailsModal(true);
                        }}
                      >
                        <i className="ri-add-fill me-1 align-bottom"></i>
                        New Account
                      </button>
                      </div>
                    </div>
                  </div>
                </CardHeader> */}
                <CardBody className="pt-0">
                  <div>
                    {loading ? (
                      <Loader
                        loader_type={app_consts.LOGO_LOADER}
                        loading_message={"Loading Users..."} />
                    ) : error ? (
                      <Loader error={error} />
                    ) : success_user_list && userListData?.length ? (
                      <TableContainer
                        columns={columns}
                        data={userListData || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        // handleContactClick={handleContactClicks}
                        isContactsFilter={true}
                        SearchPlaceholder="Search..."
                      />
                    ) : (
                      <div className="d-flex justify-content-center align-items-center">
                        No Users Found
                      </div>
                    )}
                  </div>
                  {/* <ToastContainer closeButton={false} limit={1} /> */}
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserManagement;