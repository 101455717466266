import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../utils/constants";

const initialState = {
 get_current_account: { message: "idle" },
};

export const get_current_account_Slice = createSlice({
 name: "get_current_account",
 initialState,
 reducers: {
  success_get_current_account: (state, action) => {
   if (action?.payload)
   {
    localStorage.setItem('account', JSON.stringify(action?.payload));
    state.get_current_account = { status: app_consts.SUCCESS, data: JSON.stringify(action?.payload) };
   }
   else
   {
    state.get_current_account = { status: app_consts.FAIL, message: "Authentication Failed. Please Contact System Administrator." };
   }
  },
  fail_get_current_account: (state, action) => {
   state.get_current_account = { status: app_consts.FAIL, message: action.payload };
  },
  reset_get_current_account: (state) => {
   state.get_current_account = { status: app_consts.IDLE };
  },
  loading_get_current_account: (state) => {
   state.get_current_account = { status: app_consts.LOADING };
  },
 }
});

export const { success_get_current_account, fail_get_current_account, reset_get_current_account, loading_get_current_account } = get_current_account_Slice.actions;

export default get_current_account_Slice.reducer;