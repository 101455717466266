import { configureStore } from "@reduxjs/toolkit";

// Auth Reducers
import authSIgnInReducer from "../reducers/public_reducers/auth/auth_sign_in_Slice";
import getCurrentAccountReducer from "../reducers/public_reducers/auth/get_current_account_Slice";
import signOutReducer from "../reducers/public_reducers/auth/auth_sign_out_Slice";
import authVerify2faReducer from "../reducers/public_reducers/auth/auth_verify_2fa_slice";
import authResendOTPReducer from "../reducers/public_reducers/auth/auth_resend_otp";
import authChangePasswordReducer from "../reducers/public_reducers/auth/auth_change_password_slice";
import authVerify2faOtpReducer from "../reducers/public_reducers/auth/auth_verify_2fa_otp_slice";

// Layout Reducers
import layoutSliceReducer from "../reducers/public_reducers/layout/layout_Slice";

export const public_routes_store = configureStore({
 reducer: {

  // Auth Reducers
  auth_sign_in: authSIgnInReducer,
  get_current_account: getCurrentAccountReducer,
  sign_out: signOutReducer,
  auth_verify_2fa: authVerify2faReducer,
  auth_resend_otp: authResendOTPReducer,
  auth_change_password: authChangePasswordReducer,
  auth_verify_2fa_otp: authVerify2faOtpReducer,

  // Layout Reducers
  layout_slice: layoutSliceReducer,
 },
});