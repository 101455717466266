import React from "react";
import { Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const DocumentViewer = ({ name, url, onEditClick, showUpload = false }) => {
    return (
        <Card>
            <CardHeader className="d-flex justify-content-between align-items-center bg-light border-bottom">
                <CardTitle tag="h2" className="mb-0">{name}</CardTitle>
                {showUpload && (
                    <Button color="success" size="sm" onClick={onEditClick} className="d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="me-2" width="20" height="20">
                        <path d="M9.25 13.25a.75.75 0 001.5 0V4.636l2.955 3.129a.75.75 0 001.09-1.03l-4.25-4.5a.75.75 0 00-1.09 0l-4.25 4.5a.75.75 0 101.09 1.03L9.25 4.636v8.614z" />
                        <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
                    </svg>
                    Upload Document
                </Button>
                )}
            </CardHeader>
            <CardBody>
                {url && (url.endsWith(".pdf") ? (
                    <embed src={url} type="application/pdf" width="100%" height="500px" />
                ) : (
                    <img src={url} alt={url} className="w-100" />
                ))}
            </CardBody>
        </Card>
    );
};

export default DocumentViewer;
