import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Alert, Button, Card, Col, Container, Row, Spinner } from 'reactstrap';
import * as Yup from "yup";
import { useFormik } from "formik";
import * as app_consts from '../../../utils/constants';
import { auth_actions_helper, auth_actions_helper_json_data, no_auth_actions_helper } from '../../../actions/actions_helper';
// Import images
import AuthSlider from '../authCarousel';
import { GET_CURRENT_ACCOUNT_ERR_MSG, GET_CURRENT_ACCOUNT_SUCCESS_MSG, RESEND_CODE_ERR_MSG, RESEND_CODE_SUCCESS_MSG, VERIFY_2FA_ERR_MSG, VERIFY_2FA_SUCCESS_MSG } from '../../../utils/response_messages_constants';
import { GET_CURRENT_ACCOUNT_API, RESEND_CODE_API, VERIFY_2FA_API, VERIFY_2FA_SIGN_IN_API } from '../../../api/api_endpoints';
import { fail_auth_verify_2fa, loading_auth_verify_2fa, reset_auth_verify_2fa, success_auth_verify_2fa } from '../../../reducers/public_reducers/auth/auth_verify_2fa_slice';
import { fail_get_current_account, loading_get_current_account, reset_get_current_account, success_get_current_account } from '../../../reducers/public_reducers/auth/get_current_account_Slice';
import { fail_auth_resend_otp, loading_auth_resend_otp, success_auth_resend_otp } from '../../../reducers/public_reducers/auth/auth_resend_otp';

const CoverTwosVerify = () => {
    const { email } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [otpValues, setOtpValues] = useState(Array(6).fill(""));
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [resendCodeMessage, setResendCodeMessage] = useState("");
    const [error, setError] = useState("");
    const otp = otpValues.join('');

    document.title = "Two Step Verification | TradeEase Integrated Digital Platform";

    const handleChange = (index, value) => {
        const newOtpValues = [...otpValues];
        newOtpValues[index] = value.replace(/\D/, "");
        setOtpValues(newOtpValues);

        if (value && index < 5) {
            document.getElementById(`digit${index + 2}-input`).focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === "Backspace" && !otpValues[index] && index > 0) {
            document.getElementById(`digit${index}-input`).focus();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (otp.length === 6) {
            setError("");
            setResendCodeMessage("")
            dispatch(auth_actions_helper_json_data(
                loading_auth_verify_2fa,
                success_auth_verify_2fa,
                fail_auth_verify_2fa,
                VERIFY_2FA_ERR_MSG,
                VERIFY_2FA_SUCCESS_MSG,
                app_consts.POST,
                VERIFY_2FA_SIGN_IN_API,
                { otp, email }
            ));
        } else {
            setError("Please enter all six digits.");
        }
    };

    const handleResend = () => {
        setError("")
        setResendCodeMessage("")
        dispatch(auth_actions_helper_json_data(
            loading_auth_resend_otp,
            success_auth_resend_otp,
            fail_auth_resend_otp,
            RESEND_CODE_ERR_MSG,
            RESEND_CODE_SUCCESS_MSG,
            app_consts.POST,
            RESEND_CODE_API,
            { email }
        ));
    }

    const resend_otp_listener = useSelector((state) => state.auth_resend_otp.auth_resend_otp);

    useEffect(() => {
        if (resend_otp_listener.status !== app_consts.IDLE) {
            if (resend_otp_listener.status === app_consts.LOADING) {
                setLoading(true);
                setLoadingMessage("Sending Code...");
            } else if (resend_otp_listener.status === app_consts.FAIL) {
                setError(resend_otp_listener.message);
                dispatch(reset_auth_verify_2fa());
                setLoading(false);
            } else if (resend_otp_listener.status === app_consts.SUCCESS) {
                setResendCodeMessage("OTP Code Resent Successfully");
                setLoading(false);
            }
        }
    }, [resend_otp_listener, navigate]);

    const verify_2fa_listener = useSelector((state) => state.auth_verify_2fa.auth_verify_2fa);

    useEffect(() => {
        if (verify_2fa_listener.status !== app_consts.IDLE) {
            if (verify_2fa_listener.status === app_consts.LOADING) {
                setLoading(true);
                setLoadingMessage("Verifying Code...");
            } else if (verify_2fa_listener.status === app_consts.FAIL) {
                setError(verify_2fa_listener.message);
                dispatch(reset_auth_verify_2fa());
                setLoading(false);
            } else if (verify_2fa_listener.status === app_consts.SUCCESS) {
                setTimeout(() => {
                    dispatch(auth_actions_helper(loading_get_current_account, success_get_current_account, fail_get_current_account, GET_CURRENT_ACCOUNT_ERR_MSG, GET_CURRENT_ACCOUNT_SUCCESS_MSG, app_consts.GET, GET_CURRENT_ACCOUNT_API));
                }, 2000);
            }
        }
    }, [verify_2fa_listener, navigate]);

    const get_current_account_listner = useSelector((state) => state.get_current_account.get_current_account);

    useEffect(() => {
        if (get_current_account_listner.status !== app_consts.IDLE) {
            if (get_current_account_listner.status === app_consts.LOADING) {
                setLoading(true);
                setLoadingMessage("Verifying Account...");
            }
            else {
                if (get_current_account_listner.status === app_consts.FAIL) {
                    setError(get_current_account_listner.message);
                    dispatch(reset_get_current_account());
                    setLoading(false);
                }
                else if (get_current_account_listner.status === app_consts.SUCCESS) {
                    setTimeout(() => {
                        dispatch(reset_get_current_account());
                        window.location.href = '/';
                        setLoading(false);
                    }, 1500);
                }
            }
        }
    }, [get_current_account_listner]);

    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden">
                                    <Row className="justify-content-center g-0">
                                        <AuthSlider />
                                        <Col lg={6}>
                                            <div className="p-lg-5 p-4">
                                                <div className="mb-4">
                                                    <div className="avatar-lg mx-auto">
                                                        <div className="avatar-title bg-light text-primary display-5 rounded-circle">
                                                            <i className="ri-mail-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-muted text-center mx-lg-3">
                                                    <h4 className="">Verify OTP</h4>
                                                    <p>
                                                        Please enter the 6-digit code sent to{' '}
                                                        <span className="fw-semibold" style={{ fontSize: '0.85rem' }}>{email}</span>
                                                    </p>
                                                </div>
                                                {error && (
                                                    <Alert color="danger">{error}</Alert>
                                                )}
                                                {resendCodeMessage && (
                                                    <div className="text-center mt-3">
                                                        <Alert color="success">{resendCodeMessage}</Alert>
                                                    </div>
                                                )}
                                                <form onSubmit={handleSubmit}>
                                                    <Row className="justify-content-center">
                                                        {otpValues.map((digit, index) => (
                                                            <Col className="col-2" key={index}>
                                                                <input
                                                                    id={`digit${index + 1}-input`}
                                                                    type="text"
                                                                    maxLength="1"
                                                                    className="form-control form-control-lg bg-light border-success text-center"
                                                                    value={digit}
                                                                    onChange={(e) => handleChange(index, e.target.value)}
                                                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                                                />
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                    <Button
                                                        color="success"
                                                        disabled={loading}
                                                        className="w-100 mt-4"
                                                        type="submit"
                                                    >
                                                        {loading ? <><Spinner size="sm" className='me-2' />  {loadingMessage} </> : "Verify Code"}
                                                    </Button>
                                                </form>
                                                <div className="mt-5 text-center">
                                                    <p>
                                                        Didn't receive a code?
                                                        <button
                                                            onClick={handleResend}
                                                            disabled={loading}
                                                            className={`fw-semibold text-primary btn btn-link ${loading ? 'text-muted' : ''}`}
                                                        >
                                                            Resend
                                                        </button>
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CoverTwosVerify;
