import axios from "axios";

// const API_URL = "http://192.168.1.209:4000/api";
// const API_URL = 'http://localhost:4000/api';
const API_URL = 'https://dev-trade-ease-api.zamace.co.zm/api';

// No Auth Api Helper
export const noAuthhApiHelper = (method, endpoint, data = null) => {
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };
    const config = {
        method,
        url: API_URL + endpoint,
        headers,
    };
    if (data)
    {
        if (method === "get")
        {
            config.params = data;
        } else
        {
            config.data = data;
        }
    }

    return axios(config);
};

// Auth Api Helper
export const authhApiHelperformData = (method, endpoint, data = null) => {
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json, multipart/form-data",
    };

    let authToken = localStorage.getItem("authToken");

    if (localStorage.getItem("authToken"))
    {
        headers["Authorization"] = `Bearer ${localStorage.getItem("authToken")}`;
    }

    const config = {
        method,
        url: API_URL + endpoint,
        headers,
    };

    if (data)
    {
        if (method === "get")
        {
            config.params = data;
        } else
        {
            config.data = data;
        }
    }

    return axios(config);
};

// Auth Api Helper
export const authhApiHelperJsonData = (method, endpoint, data = null) => {
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };

    let authToken = localStorage.getItem("authToken") || localStorage.getItem("2faAuthToken");

    if (authToken)
    {
        headers["Authorization"] = `Bearer ${authToken}`;
    }

    const config = {
        method,
        url: API_URL + endpoint,
        headers,
    };

    if (data)
    {
        if (method === "get")
        {
            config.params = data;
        } else
        {
            config.data = data;
        }
    }

    return axios(config);
};