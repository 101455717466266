export const SIGN_IN_API = "/auth/exchange/dashboard_login";
export const VERIFY_2FA_API = "/auth/exchange/confirm_exchange_reset_password_otp";
export const CHANGE_PASSWORD_API = "/auth/exchange/reset_exchange_password";
export const SEND_RESET_OTP_API = "/auth/exchange/send_reset_exchange_user_password_otp";
export const GET_CURRENT_ACCOUNT_API = "/exchange_dashboard/get_current_account_details";
export const VERIFY_2FA_SIGN_IN_API = "/exchange_dashboard/auth/verify_two_fa_login_otp";
export const RESEND_CODE_API = "/exchange_dashboard/auth/resend_exchange_dashboard_login_otp";
export const RETRIEVE_USER_LIST_API = "/exchange_dashboard/entities/retrieve_all_entities";
export const RETRIEVE_ENTITY_DETAILS_API = "/exchange_dashboard/entities/retrieve_entity_details_by_organization_id";
export const RETRIEVE_EVENT_LIST_API = "/exchange_dashboard/events/exchange_retrieve_events_list";
export const CREATE_TRAINING_EVENT_API = "/exchange_dashboard/events/exchange_create_training_event";
export const RETRIEVE_SPECIFIC_EVENT_DETAILS_API = "/exchange_dashboard/";
export const RETEIEVE_SUPPORT_TICKETS_LIST_API = "/exchange_dashboard/";
export const RETEIEVE_SPECIFIC_TICKET_API = "/exchange_dashboard/";