import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import withRouter from '../Components/Common/withRouter';

//import Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
// import RightSidebar from '../Components/Common/RightSidebar';

//redux
import { useSelector, useDispatch } from "react-redux";
import { change_layout, change_layout_mode, change_layout_position_type, change_sidebar_image_type, change_sidebar_size, change_sidebar_theme, change_sidebar_view, change_topbar } from '../reducers/private_reducers/layout/layout_Slice';
import { change_layout_width } from '../reducers/public_reducers/layout/layout_Slice';


const Layout = (props) => {
    const [headerClass, setHeaderClass] = useState("");
    const dispatch = useDispatch();

    const layoutSettings = useSelector(state => state.layout_slice.layout_slice);

    const {
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
    } = layoutSettings;

    /*
    layout settings
    */
    useEffect(() => {
        if (
            layoutType ||
            leftSidebarType ||
            layoutModeType ||
            layoutWidthType ||
            layoutPositionType ||
            topbarThemeType ||
            leftsidbarSizeType ||
            leftSidebarViewType ||
            leftSidebarImageType
        )
        {
            dispatch(change_sidebar_view(leftSidebarViewType));
            dispatch(change_sidebar_size(leftsidbarSizeType));
            dispatch(change_sidebar_theme(leftSidebarType));
            dispatch(change_layout_mode(layoutModeType));
            dispatch(change_layout_width(layoutWidthType));
            dispatch(change_layout_position_type(layoutPositionType));
            dispatch(change_topbar(topbarThemeType));
            dispatch(change_layout(layoutType));
            dispatch(change_sidebar_image_type(leftSidebarImageType));
        }
    }, [layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
        dispatch]);
    /*
    call dark/light mode
    */
    const onChangeLayoutMode = (value) => {
        if (change_layout_mode)
        {
            dispatch(change_layout_mode(value));
        }
    };

    // class add remove in header
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });
    function scrollNavigation () {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50)
        {
            setHeaderClass("topbar-shadow");
        } else
        {
            setHeaderClass("");
        }
    }

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header
                    headerClass={headerClass}
                    layoutModeType={layoutModeType}
                    onChangeLayoutMode={onChangeLayoutMode} />
                <Sidebar layoutType={layoutType} />
                <div className="main-content">{props.children}
                    <Footer />
                </div>
            </div>
        </React.Fragment>

    );
};

Layout.propTypes = {
    children: PropTypes.object,
};

export default withRouter(Layout);