import { createSlice } from "@reduxjs/toolkit";
import * as layout_consts from "../../../utils/layout_constants";

const initialState = {
 layout_slice: {
  layoutType: layout_consts.layoutTypes.VERTICAL,
  leftSidebarType: layout_consts.leftSidebarTypes.LIGHT,
  layoutModeType: layout_consts.layoutModeTypes.LIGHTMODE,
  layoutWidthType: layout_consts.layoutWidthTypes.FLUID,
  layoutPositionType: layout_consts.layoutPositionTypes.FIXED,
  topbarThemeType: layout_consts.topbarThemeTypes.LIGHT,
  leftsidbarSizeType: layout_consts.leftsidbarSizeTypes.DEFAULT,
  leftSidebarViewType: layout_consts.leftSidebarViewTypes.DEFAULT,
  leftSidebarImageType: layout_consts.leftSidebarImageTypes.NONE,
  preloader: layout_consts.preloaderTypes.ENABLE,
 },
};

const changeHTMLAttribute = (attribute, value) => {
 if (document.documentElement) document.documentElement.setAttribute(attribute, value);
 return true;
};

export const layout_Slice = createSlice({
 name: "layout_slice",
 initialState,
 reducers: {
  change_layout: (state, action) => {
   state.layout_slice.layoutType = action.payload;
   if (action.payload === "twocolumn")
   {
    document.documentElement.removeAttribute("data-layout-width");
   } else if (action.payload === "horizontal")
   {
    document.documentElement.removeAttribute("data-sidebar-size");
   }
   changeHTMLAttribute("data-layout", action.payload);
  },
  change_layout_mode: (state, action) => {
   state.layout_slice.layoutModeType = action.payload;
   changeHTMLAttribute("data-layout-mode", action.payload);
  },
  change_sidebar_theme: (state, action) => {
   state.layout_slice.leftSidebarType = action.payload;
   changeHTMLAttribute("data-sidebar", action.payload);
  },
  change_layout_width: (state, action) => {
   state.layout_slice.layoutWidthType = action.payload;
   if (action.payload === 'lg')
   {
    changeHTMLAttribute("data-layout-width", "fluid");
   } else
   {
    changeHTMLAttribute("data-layout-width", "boxed");
   }
  },
  change_topbar: (state, action) => {
   state.layout_slice.topbarThemeType = action.payload;
   changeHTMLAttribute("data-topbar", action.payload);
  },
  change_sidebar_size: (state, action) => {
   state.layout_slice.leftsidbarSizeType = action.payload;
   if (action.payload === 'lg')
   {
    changeHTMLAttribute("data-sidebar-size", "lg");
   } else if (action.payload === 'md')
   {
    changeHTMLAttribute("data-sidebar-size", "md");
   } else if (action.payload === 'sm')
   {
    changeHTMLAttribute("data-sidebar-size", "sm");
   } else if (action.payload === 'sm-hover')
   {
    changeHTMLAttribute("data-sidebar-size", "sm-hover");
   } else
   {
    changeHTMLAttribute("data-sidebar-size", "lg");
   }
  },
  change_sidebar_view: (state, action) => {
   state.layout_slice.leftSidebarViewType = action.payload;
   changeHTMLAttribute("data-layout-style", action.payload);
  },
  change_sidebar_image_type: (state, action) => {
   state.layout_slice.leftSidebarImageType = action.payload;
   changeHTMLAttribute("data-sidebar-image", action.payload);
  },
  reset_value: (state, action) => {
   state.layout_slice.resetValue = action.payload;
  },
  change_preloader: (state, action) => {
   state.layout_slice.preloader = action.payload;
   changeHTMLAttribute("data-preloader", action.payload);
  },
  change_layout_position_type: (state, action) => {
   state.layout_slice.layoutPositionType = action.payload;
   changeHTMLAttribute("data-layout-position", action.payload);
  },
 }
});



export const { change_layout, change_layout_mode, change_sidebar_theme, change_layout_width, change_topbar, change_sidebar_size, change_sidebar_view, change_sidebar_image_type, reset_value, change_preloader, change_layout_position_type } = layout_Slice.actions;

export default layout_Slice.reducer;
