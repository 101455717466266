import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../utils/constants";

const initialState = {
    entity_list: { status: "idle" },
};

export const entity_list_Slice = createSlice({
    name: "entity_list",
    initialState,
    reducers: {
        success_entity_list: (state, action) => {
            state.entity_list = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_entity_list: (state, action) => {
            state.entity_list = { status: app_consts.FAIL, message: action.payload };
        },
        reset_entity_list: (state) => {
            state.entity_list = { status: app_consts.IDLE };
        },
        loading_entity_list: (state) => {
            state.entity_list = { status: app_consts.LOADING };
        },
    }
});

export const { success_entity_list, fail_entity_list, reset_entity_list, loading_entity_list } = entity_list_Slice.actions;

export default entity_list_Slice.reducer;