import { authhApiHelperformData, authhApiHelperJsonData, noAuthhApiHelper } from "../api/api_helpers";
import { success_sign_out } from "../reducers/public_reducers/auth/auth_sign_out_Slice";
import * as app_consts from "../utils/constants";
import { messageToast } from "../utils/functionalUtils";

// Auth Actions Helper
export const auth_actions_helper =
  (
    loadingState,
    successState,
    failState,
    errorMessage,
    successMessage,
    apiReqType,
    apiEndpoint,
    formData = null,
    accessDeniedMessage = null,
    res_action = null,
    res_type = null
  ) =>
    async (dispatch) => {

      try
      {
        //Set Loading
        dispatch(loadingState());
        //Making API Request
        const response = await authhApiHelperformData(apiReqType, apiEndpoint, formData);
        dispatch(successState(response));
      } catch (error)
      {
        console.log(error);
        if (error) 
        {
          // Server or Network Error
          if (error?.code === "ERR_NETWORK" || error?.code === "ERR_BAD_RESPONSE")
          {
            dispatch(failState(errorMessage));
          } else
          {
            // Bad Response Message
            if (error?.status === 400 || error?.status === 404)
            {
              dispatch(failState(error?.data?.message));
            }
            // Unauthorized
            else if (error?.status === 401)
            {
              dispatch(success_sign_out());
              localStorage.clear();
              window.location.href = '/login';
            }
            // Access Denied
            else if (error?.status === 403)
            {
              messageToast(accessDeniedMessage, app_consts.FAIL);
              dispatch(failState(accessDeniedMessage));
            }
            else
            {
              dispatch(failState(errorMessage));
            }
          }
        }
      }
    };

    // Auth Actions Helper
export const auth_actions_helper_json_data =
  (
    loadingState,
    successState,
    failState,
    errorMessage,
    successMessage,
    apiReqType,
    apiEndpoint,
    formData = null,
    accessDeniedMessage = null,
    res_action = null,
    res_type = null
  ) =>
    async (dispatch) => {

      try
      {
        //Set Loading
        dispatch(loadingState());
        //Making API Request
        const response = await authhApiHelperJsonData(apiReqType, apiEndpoint, formData);
        dispatch(successState(response));
      } catch (error)
      {
        console.log(error);
        if (error) 
        {
          // Server or Network Error
          if (error?.code === "ERR_NETWORK" || error?.code === "ERR_BAD_RESPONSE")
          {
            dispatch(failState(errorMessage));
          } else
          {
            // Bad Response Message
            if (error?.status === 400 || error?.status === 404)
            {
              dispatch(failState(error?.data?.message));
            }
            // Unauthorized
            else if (error?.status === 401)
            {
              dispatch(success_sign_out());
              localStorage.clear();
              window.location.href = '/login';
            }
            // Access Denied
            else if (error?.status === 403)
            {
              messageToast(accessDeniedMessage, app_consts.FAIL);
              dispatch(failState(accessDeniedMessage));
            }
            else
            {
              dispatch(failState(errorMessage));
            }
          }
        }
      }
    };


// No Auth Actions Helper
export const no_auth_actions_helper =
  (loadingState, successState, failState, errorMessage, successMessage, apiReqType, apiEndpoint, formData = null) =>
    async (dispatch) => {
      try
      {
        //Set Loading
        dispatch(loadingState());
        //Making API Request
        const response = await noAuthhApiHelper(apiReqType, apiEndpoint, formData);
        
        dispatch(successState(response));
      } catch (error)
      {
        console.log(error);
        if (error)
        {
          // Server or Network Error
          if (error?.code === "ERR_NETWORK" || error?.code === "ERR_BAD_RESPONSE")
          {
            dispatch(failState(errorMessage));
          } else
          {
            // Bad Response Message
            if (error?.status === 400)
            {
              dispatch(failState(error?.data?.message));
            }
            else
            {
              dispatch(failState("Could Not Process Request."));
            }
          }
        }
      }
    };
