import React, { useEffect } from 'react';
import withRouter from '../Components/Common/withRouter';

//redux
import { useSelector } from "react-redux";

const NonAuthLayout = ({ children }) => {

    const layout_slice_listener = useSelector(state => ({ layoutModeType: state.layout_slice.layout_slice }));

    useEffect(() => {
        if (layout_slice_listener === "dark")
        {
            document.body.setAttribute("data-layout-mode", "dark");
        } else
        {
            document.body.setAttribute("data-layout-mode", "light");
        }
        return () => {
            document.body.removeAttribute("data-layout-mode");
        };
    }, [layout_slice_listener]);
    return (
        <div>
            {children}
        </div>
    );
};

export default withRouter(NonAuthLayout);