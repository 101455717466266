import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Alert, Button, Card, Col, Container, Row, Spinner } from 'reactstrap';
import * as Yup from "yup";
import { useFormik } from "formik";
import * as app_consts from '../../../utils/constants';
import { no_auth_actions_helper } from '../../../actions/actions_helper';
// Import images
import AuthSlider from '../authCarousel';
import { VERIFY_2FA_ERR_MSG, VERIFY_2FA_SUCCESS_MSG } from '../../../utils/response_messages_constants';
import { VERIFY_2FA_API } from '../../../api/api_endpoints';
import { fail_auth_verify_2fa_otp, loading_auth_verify_2fa_otp, reset_auth_verify_2fa_otp, success_auth_verify_2fa_otp } from '../../../reducers/public_reducers/auth/auth_verify_2fa_otp_slice';

const ConfirmTwoFa = () => {
    const { email } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [otpValues, setOtpValues] = useState(Array(6).fill(""));
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [error, setError] = useState("");
    const otp = otpValues.join('');

    document.title = "Two Step Verification | TradeEase Integrated Digital Platform";

    const handleChange = (index, value) => {
        const newOtpValues = [...otpValues];
        newOtpValues[index] = value.replace(/\D/, "");
        setOtpValues(newOtpValues);

        if (value && index < 5) {
            document.getElementById(`digit${index + 2}-input`).focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === "Backspace" && !otpValues[index] && index > 0) {
            document.getElementById(`digit${index}-input`).focus();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (otp.length === 6) {
            setError("");
            dispatch(no_auth_actions_helper(
                loading_auth_verify_2fa_otp,
                success_auth_verify_2fa_otp,
                fail_auth_verify_2fa_otp,
                VERIFY_2FA_ERR_MSG,
                VERIFY_2FA_SUCCESS_MSG,
                app_consts.POST,
                VERIFY_2FA_API,
                { otp, email }
            ));
        } else {
            setError("Please enter all six digits.");
        }
    };

    const verify_2fa_otp_listener = useSelector((state) => state.auth_verify_2fa_otp.auth_verify_2fa_otp);
    
    useEffect(() => {
        if (verify_2fa_otp_listener.status !== app_consts.IDLE) {
            if (verify_2fa_otp_listener.status === app_consts.LOADING) {
                setLoading(true);
                setLoadingMessage("Verifying Code...");
            } else if (verify_2fa_otp_listener.status === app_consts.FAIL) {
                setError(verify_2fa_otp_listener.message);
                dispatch(reset_auth_verify_2fa_otp());
                setLoading(false);
            } else if (verify_2fa_otp_listener.status === app_consts.SUCCESS) {
                setLoading(false);
                navigate(`/change-password/${email}/${otp}`);
            }
        }
    }, [verify_2fa_otp_listener, navigate]);

    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden">
                                    <Row className="justify-content-center g-0">
                                        <AuthSlider />
                                        <Col lg={6}>
                                            <div className="p-lg-5 p-4">
                                            <div className="mb-4">
                                                     <div className="avatar-lg mx-auto">
                                                         <div className="avatar-title bg-light text-primary display-5 rounded-circle">
                                                             <i className="ri-mail-line"></i>
                                                         </div>
                                                     </div>
                                                 </div>
                                                 <div className="text-muted text-center mx-lg-3">
                                                     <h4 className="">Verify OTP</h4>
                                                     <p>Please enter the 6 digit code sent to <span className="fw-semibold">{email}</span></p>
                                                 </div>
                                                {error && (
                                                    <Alert color="danger">{error}</Alert>
                                                )}
                                                <form onSubmit={handleSubmit}>
                                                    <Row className="justify-content-center">
                                                        {otpValues.map((digit, index) => (
                                                            <Col className="col-2" key={index}>
                                                                <input
                                                                    id={`digit${index + 1}-input`}
                                                                    type="text"
                                                                    maxLength="1"
                                                                    className="form-control form-control-lg bg-light border-success text-center"
                                                                    value={digit}
                                                                    onChange={(e) => handleChange(index, e.target.value)}
                                                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                                                />
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                    <Button
                                                        color="success"
                                                        disabled={loading}
                                                        className="w-100 mt-4"
                                                        type="submit"
                                                    >
                                                        { loading ? <><Spinner size="sm" className='me-2' />  {loadingMessage} </> : "Verify Code" }
                                                    </Button>
                                                </form>
                                                <div className="mt-5 text-center">
                                                    <p>Didn't receive a code? <Link to="/resend-otp" className="fw-semibold text-primary">Resend</Link></p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ConfirmTwoFa;
