import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../utils/constants";

const initialState = {
    create_training_event: { status: "idle" },
};

export const create_training_event_Slice = createSlice({
    name: "create_training_event",
    initialState,
    reducers: {
        success_create_training_event: (state, action) => {
            state.create_training_event = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_create_training_event: (state, action) => {
            state.create_training_event = { status: app_consts.FAIL, message: action.payload };
        },
        reset_create_training_event: (state) => {
            state.create_training_event = { status: app_consts.IDLE };
        },
        loading_create_training_event: (state) => {
            state.create_training_event = { status: app_consts.LOADING };
        },
    }
});

export const { success_create_training_event, fail_create_training_event, reset_create_training_event, loading_create_training_event } = create_training_event_Slice.actions;

export default create_training_event_Slice.reducer;