// Route Prefixes
export const BROKER = "broker";
export const WAREHOUSE = "warehouse";
export const WAREHOUSE_OPERATOR = "WAREHOUSE_OPERATOR";

// Wizard Step Card Types
export const CREATE_WAREHOUSE_RECEIPT_WIZARD = "create_warehouse_receipt_wizard";
export const CREATE_BULK_WAREHOUSE_RECEIPT_WIZARD = "CREATE_BULK_WAREHOUSE_RECEIPT_WIZARD";

// Commodities With UnderScrote
export const SOYA_BEANS = "soya_beans";
export const WHEAT = "wheat";
export const SUNFLOWER_SEED = "sunflower_seed";
export const MAIZE = "maize";

// Page Navigation Constansts
export const SPECIFIC_WAREHOUSE = "SPECIFIC_WAREHOUSE";
export const WAREHOUSE_LOCATIONS = "WAREHOUSE_LOCATIONS";
export const CUSTOMER_MANAGEMENT = "CUSTOMER_MANAGEMENT";
export const OFFER_MANAGEMENT = "OFFER_MANAGEMENT";
export const WAREHOUSE_RECEIPTS = "WAREHOUSE_RECEIPTS";
export const SPECIFIC_WAREHOUSE_RECEIPT = "SPECIFIC_WAREHOUSE_RECEIPT";
export const INBOUND_SHIPMENTS = "INBOUND_SHIPMENTS";
export const SPECIFIC_INBOUND_SHIPMENT_WAREHOUSE = "SPECIFIC_INBOUND_SHIPMENT_WAREHOUSE";
export const MEMBER_MANAGEMENT = "MEMBER_MANAGEMENT";
export const MARKET_OFFER_MANAGEMENT = "MARKET_OFFER_MANAGEMENT";
export const WAREHOUSE_STORAGES = "WAREHOUSE_STORAGES";
export const RECEIPTS_REPOSITORY = "RECEIPTS_REPOSITORY";
export const OFFER_TRADE_DETAILS = "OFFER_TRADE_DETAILS";
export const BID_MANAGEMENT = "BID_MANAGEMENT";
export const BID_DETAILS = "BID_DETAILS";
export const PROCESS_WAREHOUSE_RECEIPT = "PROCESS_WAREHOUSE_RECEIPT";
export const OFFER_DETAILS = "OFFER_DETAILS";
export const TRADE_FULFILLMENTS = "TRADE_FULFILLMENTS";
export const SPECIFIC_TRADE_FULFILLMENTS = "SPECIFIC_TRADE_FULFILLMENTS";
export const AUTOMATED_TRADING_SYSTEM = "AUTOMATED_TRADING_SYSTEM";
export const ATS_DETAILS = "ATS_DETAILS";
export const PROVISION_RECEIPTS = "PROVISION_RECEIPTS";
export const EXCHANGE_PROVISION_RECEIPTS = "EXCHANGE_PROVISION_RECEIPTS";
export const WAREHOUSE_OPERATOR_PROFILE = "WAREHOUSE_OPERATOR_PROFILE";
export const WAREHOUSE_OPERATOR_USER_PROFILE = "WAREHOUSE_OPERATOR_USER_PROFILE";
export const SPECIFIC_PROVISION_RECEIPT = "SPECIFIC_PROVISION_RECEIPT";


// Entity Category Suffixes
export const WAREHOUSE_OPERATOR_SUFFIX = "WO";
export const BROKER_SUFFIX = "BK";
export const COOPERATIVE_SUFFIX = "CO";
export const SERVICE_PROVIDER_SUFFIX = "SP";
export const INVESTOR_SUFFIX = "IN";
export const FINANCIAL_INSTITUTION_SUFFIX = "FI";
export const EXCHANGE_SUFFIX = "EX";
export const GENERAL_MEMBERSHIP_SUFFIX = "GM";

// Statuses
export const CERTIFIED = "certified";
export const UNCERTIFIED = "uncertified";
export const APPROVED = "approved";
export const PENDING = "pending";
export const EXPIRED = "expired";
export const CANCELLED = "cancelled";
export const IDLE = "idle";
export const CLOSED = "closed";

// Map Types
export const WAREHOUSES_MAP = "WAREHOUSES_MAP";
export const PRODUCERS_LIST_MAP = "PRODUCERS_LIST_MAP";
export const PENDING_ENTITY_LIST_MAP = "PENDING_ENTITY_LIST_MAP";
export const SERVICE_PROVIDER_LIST_MAP = "SERVICE_PROVIDER_LIST_MAP";
export const AVAILABLE_STORAGE_LIST_MAP = "AVAILABLE_STORAGE_LIST_MAP";
export const WAREHOUSES_MAP_VR_LIST = "WAREHOUSES_MAP_VR_LIST";
export const ENTITY_LIST_MAP = "ENTITY_LIST_MAP";
export const INDIVIDUAL = "INDIVIDUAL";
export const ACTIVE_BROKER_LIST_MAP = "ACTIVE_BROKER_LIST_MAP";
export const WAREHOUSES_PR_CREATE_MAP = "WAREHOUSES_PR_CREATE_MAP";
export const ATS_PRODUCERS_LIST_MAP = "ATS_PRODUCERS_LIST_MAP";

// Entity Types
export const SERVICE_PROVIDER = "service_provider";

// Tables
export const WAREHOUSE_RECEIPT_TABLE = "WAREHOUSE_RECEIPT_TABLE";
export const OFFER_HISTORY_TABLE = "OFFER_HISTORY_TABLE";
export const BROKER_OFFER_TRADE_TABLE = "BROKER_OFFER_TRADE_TABLE";
export const BID_MANAGEMENT_TABLE = "BID_MANAGEMENT_TABLE";
export const WAREHOUSE_RECEIPT_LIST_TABLE = "WAREHOUSE_RECEIPT_LIST_TABLE";
export const TRADE_FULFILLMENTS_LIST_TABLE = "TRADE_FULFILLMENTS_LIST_TABLE";
export const SUPER_BID_MANAGEMENT_TABLE = "SUPER_BID_MANAGEMENT_TABLE";
export const ENTITY_USER_PROFILES_TABLE = "ENTITY_USER_PROFILES_TABLE";
export const PROVISION_RECEIPT_LIST_TABLE = "PROVISION_RECEIPT_LIST_TABLE";

// Table Rows
export const PAGE_LINK_ROW = "PAGE_LINK_ROW";
export const TEXT_ROW = "TEXT_ROW";
export const TEXT_LINK = "TEXT_LINK";
export const TEXT_STATUS = "TEXT_STATUS";
export const TEXT_VIEW_DETAILS = "TEXT_VIEW_DETAILS";
export const TEXT_CHECKBOX = "TEXT_CHECKBOX";

// Doc Type
export const SYSTEM_INVOICE_GENERATED_PDF = "SYSTEM_INVOICE_GENERATED_PDF";
export const SYSTEM_CONTRACT_GENERATED_PDF = "SYSTEM_CONTRACT_GENERATED_PDF";

// Trade
export const SOLD = "SOLD";
export const SUPER_OFFER = "super_offer";
export const SUPER_BID = "super_bid";

// Other
export const BULK_DELIVERY_PROCESSING = "BULK_DELIVERY_PROCESSING";
export const SPINNER_LOADER = "SPINNER_LOADER";
export const LOGO_LOADER = "LOGO_LOADER";

// Feedback Messages
export const SUCCESS = "SUCCESS";
export const LOADING = "LOADING";
export const FAIL = "FAIL";
export const PAGE_ACCESS_DENIED = "PAGE_ACCESS_DENIED";

// Request Type
export const GET = "GET";
export const POST = "POST";