import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../utils/constants";

const initialState = {
    event_list: { status: "idle" },
};

export const event_list_Slice = createSlice({
    name: "event_list",
    initialState,
    reducers: {
        success_event_list: (state, action) => {
            state.event_list = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_event_list: (state, action) => {
            state.event_list = { status: app_consts.FAIL, message: action.payload };
        },
        reset_event_list: (state) => {
            state.event_list = { status: app_consts.IDLE };
        },
        loading_event_list: (state) => {
            state.event_list = { status: app_consts.LOADING };
        },
    }
});

export const { success_event_list, fail_event_list, reset_event_list, loading_event_list } = event_list_Slice.actions;

export default event_list_Slice.reducer;