import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import withRouter from "../../../Components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import * as app_consts from '../../../utils/constants';
import { Card, Col, Container, Input, Label, Row ,Button, Spinner, Alert} from 'reactstrap';
import AuthSlider from '../authCarousel';
import { fail_auth_sign_in, loading_auth_sign_in, reset_auth_sign_in, success_auth_sign_in } from '../../../reducers/public_reducers/auth/auth_sign_in_Slice';
import { no_auth_actions_helper } from '../../../actions/actions_helper';
import { SIGN_IN_API } from '../../../api/api_endpoints';
import { SIGN_IN_ERR_MSG, SIGN_IN_SUCCESS_MSG } from '../../../utils/response_messages_constants';

const CoverSignIn = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [loadingMessage, setloadingMessage] = useState("");

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: email || '',
            password: password || '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Please Enter a Valid Email").required("Email is Required"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values) => {
            // setError("");
            dispatch(no_auth_actions_helper(loading_auth_sign_in, success_auth_sign_in, fail_auth_sign_in, SIGN_IN_ERR_MSG, SIGN_IN_SUCCESS_MSG, app_consts.POST, SIGN_IN_API, { email: values.email, password: values.password }));
        }
    });

    document.title="SignIn | TradeEase Dashboard";

    // Listen to Dispatch
    const sigin_listener = useSelector((state) => state.auth_sign_in.auth_sign_in);

    useEffect(() => {
        if (sigin_listener.status !== app_consts.IDLE) {
            if (sigin_listener.status === app_consts.LOADING) {
                setLoading(true);
                setloadingMessage("Loading...");
            }
            else {
                if (sigin_listener.status === app_consts.FAIL) {
                    setError(sigin_listener.message);
                    dispatch(reset_auth_sign_in());
                    setLoading(false);
                }
                else if (sigin_listener.status === app_consts.SUCCESS) {
                   
                    navigate(`/2fa/${email}`);
                }
            }
        }
    }, [sigin_listener]);

    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>

                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden">
                                    <Row className="g-0">
                                        <AuthSlider />

                                        <Col lg={6}>
                                            <div className="p-lg-5 p-4">
                                                <div>
                                                    <h5 className="text-primary">Registered Member Access</h5>
                                                    <p className="text-muted">Sign in to your account.</p>
                                                </div>
                                                
                                                {error && (
                                            <>
                                                <Alert color="danger">
                                                    <div className='d-flex flex-row align-items-center'>
                                                        <i className='ri-error-warning-line fs-16' />
                                                        <div className='mx-2'>{error}</div>
                                                    </div>
                                                </Alert>
                                            </>
                                        )

                                        }

                                                <div className="mt-4">
                                                <form onSubmit={validation.handleSubmit}>

                                                        <div className="mb-3">
                                                            <Label htmlFor="email" className="form-label">Email</Label>
                                                            <Input type="text" className="form-control" id="email" placeholder="Enter email" value={email}
                                                        onChange={(e) => setEmail(e.target.value)} required/>
                                                        </div>

                                                        <div className="mb-3">
                                                            <div className="float-end">
                                                                <Link to="/reset-password" className="text-muted">Forgot password?</Link>
                                                            </div>
                                                            <Label className="form-label" htmlFor="password-input">Password</Label>
                                                            <div className="position-relative auth-pass-inputgroup mb-3">
                                                                <Input type="password" className="form-control pe-5 password-input" placeholder="Enter password" id="password-input" value={password}
                                                            onChange={(e) => setPassword(e.target.value)} required/>
                                                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                            </div>
                                                        </div>

                                                        <div className="form-check">
                                                            <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                            <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                                        </div>

                                                        <div className="mt-4">
                                                        <Button color="success" disabled={error ? null : loading ? true : false} className="btn btn-success w-100 flex flex-row" type="submit">
                                                        {
                                                            loading ? <><Spinner size="sm" className='me-2' />  {loadingMessage} </> : "Sign In"
                                                        }
                                                    </Button>
                                                        </div>

                                                    </form>
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CoverSignIn;