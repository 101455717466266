import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../utils/constants";

const initialState = {
    retrieve_event_details: { status: "idle" },
};

export const retrieve_event_details_Slice = createSlice({
    name: "retrieve_event_details",
    initialState,
    reducers: {
        success_retrieve_event_details: (state, action) => {
            state.retrieve_event_details = { status: app_consts.SUCCESS, data: action.payload };
        },
        fail_retrieve_event_details: (state, action) => {
            state.retrieve_event_details = { status: app_consts.FAIL, message: action.payload };
        },
        reset_retrieve_event_details: (state) => {
            state.retrieve_event_details = { status: app_consts.IDLE };
        },
        loading_retrieve_event_details: (state) => {
            state.retrieve_event_details = { status: app_consts.LOADING };
        },
    }
});

export const { success_retrieve_event_details, fail_retrieve_event_details, reset_retrieve_event_details, loading_retrieve_event_details } = retrieve_event_details_Slice.actions;

export default retrieve_event_details_Slice.reducer;