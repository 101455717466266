import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../utils/constants";

const initialState = {
 sign_out: { status: "idle" },
};

export const sign_out_Slice = createSlice({
 name: "sign_out",
 initialState,
 reducers: {
  success_sign_out: (state) => {
   localStorage.clear();
   window.location.href("/");
   state.sign_out = { status: app_consts.SUCCESS };
  }
 }
});

export const { success_sign_out } = sign_out_Slice.actions;

export default sign_out_Slice.reducer;