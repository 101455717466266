import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Icon from '../assets/images/svg/Icon';

const RedirectHelper = () => {
    const [opacity, setOpacity] = useState(1);
    const [direction, setDirection] = useState('decreasing');

    useEffect(() => {
        window.location.href = '/dashboard';
    })

    useEffect(() => {
        const intervalId = setInterval(() => {
            setOpacity(prevOpacity => {
                if (prevOpacity <= 0.2) {
                    setDirection('increasing');
                    return prevOpacity + 0.1;
                } else if (prevOpacity >= 1) {
                    setDirection('decreasing');
                    return prevOpacity - 0.1;
                } else {
                    return direction === 'decreasing' ? prevOpacity - 0.1 : prevOpacity + 0.1;
                }
            });
        }, 100); // Adjust the speed of the animation by changing the interval (in ms)

        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, [direction]);

    return (
        <div
            className='d-flex flex-column justify-content-center align-items-center'
            style={{ opacity: opacity, width: '100vw', height: '100vh' }}
        >
            <Icon style={{ width: '20%', height: '20%' }} />
            <span className='text-body mt-4 fs-4 text-center'>{"Loading. Please wait..."}</span>
        </div>
    );
};

export default RedirectHelper;
