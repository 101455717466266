import { configureStore } from "@reduxjs/toolkit";

// Layout Reducers
import layoutSliceReducer from "../reducers/private_reducers/layout/layout_Slice";

// Entities
import entityListReducer from "../reducers/private_reducers/entities/entity_list_Slice";
import userListReducer from "../reducers/private_reducers/user_list_slice.js";
import retrieveEntityDetails from "../reducers/private_reducers/entities/retrieve_entity_details_slice.js";
import eventListReducer from "../reducers/private_reducers/events/event_list_slice.js";
import retrieveEventDetailsReducer from "../reducers/private_reducers/events/retrieve_event_details_slice.js";
import createTrainingEventReducer from "../reducers/private_reducers/events/create_training_event_slice.js";

export const private_routes_store = configureStore({
 reducer: {

  // Layout Reducers
  layout_slice: layoutSliceReducer,

  // Entities
  entity_list: entityListReducer,

  user_list: userListReducer,
  retrieve_entity_details: retrieveEntityDetails,
  event_list: eventListReducer,
  retrieve_event_details: retrieveEventDetailsReducer,
  create_training_event: createTrainingEventReducer
 },
});