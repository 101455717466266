// Auth Response Messages
export const SIGN_IN_ERR_MSG = "Could Not Sign In";
export const SIGN_IN_SUCCESS_MSG = "Sign In Successful.";

export const VERIFY_2FA_ERR_MSG = "Could Not Verify Code. Please Try Again Later";
export const VERIFY_2FA_SUCCESS_MSG = "Code Verified Successful.";

export const RESEND_CODE_ERR_MSG = "Could Not Resend Code. Please Try Again Later";
export const RESEND_CODE_SUCCESS_MSG = "Code Resent Successful.";

export const CHANGE_PASSWORD_ERR_MSG = "Could Not Change Password. Please Try Again Later";
export const CHANGE_PASSWORD_SUCCESS_MSG = "Password Changed Successful.";

// Get Account Response Messages
export const GET_CURRENT_ACCOUNT_ERR_MSG = "Could Not Verify Account";
export const GET_CURRENT_ACCOUNT_SUCCESS_MSG = "Account Verified.";

export const RETRIEVE_USER_LIST_ERR_MSG = "Could Not Retrieve User List";
export const RETRIEVE_USER_LIST_SUCCESS_MSG = "User List Retrieved Successfully";

export const RETRIEVE_ENTITY_ERR_MSG = "Could Not Retrieve Details";
export const RETRIEVE_ENTITY_SUCCESS_MSG = "Entity Details Retrieved Successfully";

export const RETRIEVE_EVENT_LIST_ERR_MSG = "Could Not Retrieve Events";
export const RETRIEVE_EVENT_LIST_SUCCESS_MSG = "Events Retrieved Successfully";

export const RETRIEVE_EVENT_DETAILS_ERR_MSG = "Could Not Retrieve Events Details";
export const RETRIEVE_EVENT_DETAILS_SUCCESS_MSG = "Event Details Retrieved Successfully";

export const CREATE_TRAINING_EVENT_ERR_MSG = "Could Not Create Training Event";
export const CREATE_TRAINING_EVENT_SUCCESS_MSG = "Training Event Created Successfully";

