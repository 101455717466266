import React, { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch } from "react-redux";
import { success_sign_out } from "../reducers/public_reducers/auth/auth_sign_out_Slice";

const isAuthenticated = () => {
  const token = localStorage.getItem('authToken');
  const account = localStorage.getItem('account');
  const tokenCreatedAt = localStorage.getItem('tokenCreatedAt');

  if (token && account && tokenCreatedAt)
  {
    const currentTime = new Date().getTime();
    const tokenCreatedTime = new Date(tokenCreatedAt).getTime();
    const timeDifference = currentTime - tokenCreatedTime;
    const oneHourInMilliseconds = 60 * 60 * 1000;

    if (timeDifference > oneHourInMilliseconds)
    {
      localStorage.clear();
      return false;
    }

    return true;
  }

  return false;
};

const AuthProtected = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (!isAuthenticated())
    {
      dispatch(success_sign_out());
    } else
    {
      setAuthorization(token);
    }
  }, [dispatch]);

  if (!isAuthenticated())
  {
    window.location.href = '/login';
  }

  return <>{children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
